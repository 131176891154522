import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import "./style.css";
import CreateAssignment from './CreateAssignment';
import AssignCohort from './AssignCohort';
import AssignedMarker from './AssignMarker';
import { ArrowLeftIcon, CollapseIcon, CommonFileCheckIcon, CrossIcon, ExpandIcon } from '@/app/assets/icons/svg';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { GET_ASSIGNMENT } from '@/app/redux/actionTypes';
import ApiRequest from '@/app/api/ApiRequest';
import axios from 'axios';
interface FormData {
  assessmentId: string;
  unitCode: string;
  unitTitle: string;
  level: string;
  centerNumber: string;
  cohorts: string[];
  centerName: string;
  releasedDate: string;
  lastModeration: string;
  dueDate: string;
  description: string;
  assignmentBriefFiles: any[]; // Will now hold base64-encoded objects
  assignmentCriteriaFiles: any[]; // Same idea
  markers: string[];
  learners: string[];
}

interface CreateNewAssignmentProps {
  isOpen: boolean;
  onClose: () => void;
  setGetAssignment?: (value: boolean) => void;
}

const CreateNewAssignment: React.FC<CreateNewAssignmentProps> = ({
  isOpen,
  onClose,
  setGetAssignment,
}) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState<FormData>({
    assessmentId: '',
    unitCode: '',
    unitTitle: '',
    level: '',
    centerNumber: '',
    centerName: '',
    cohorts: [],
    releasedDate: '',
    lastModeration: new Date().toLocaleDateString(),
    dueDate: '',
    description: '',
    assignmentBriefFiles: [],
    assignmentCriteriaFiles: [],
    markers: [],
    learners: [],
  });

  const [isMaximized, setIsMaximized] = useState(false);
  const [step, setStep] = useState(1);
  const [isPublished, setIsPublished] = useState(false);
  const [assessment, setAssessment] = useState('');
  const [anyAssigned, setAnyAssigned] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState(false);

  // 1) Basic input changes
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // 2) **File change**: Convert each file to base64 before storing
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    field: 'assignmentBriefFiles' | 'assignmentCriteriaFiles'
  ) => {
    const selectedFiles = event.target.files;
    if (!selectedFiles || selectedFiles.length === 0) {
      setFormData((prev) => ({
        ...prev,
        [field]: [],
      }));
      return;
    }

    const fileArray = Array.from(selectedFiles);

    // Convert each File to a base64-encoded object { filename, mimeType, content }
    try {
      const encodedFiles = await Promise.all(
        fileArray.map((file) => {
          return new Promise<any>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              // e.target.result => data:application/pdf;base64,XYZ
              const dataUrl = e.target?.result as string;
              const base64Match = dataUrl.split(',')[1]; // everything after the comma

              resolve({
                filename: file.name,
                mimeType: file.type,
                content: base64Match || '',
              });
            };
            reader.onerror = (err) => reject(err);
            reader.readAsDataURL(file); // triggers onload
          });
        })
      );

      // Store the array of base64-encoded files in formData
      setFormData((prev) => ({
        ...prev,
        [field]: encodedFiles,
      }));
    } catch (error) {
      console.error('Error reading file(s):', error);
      Swal.fire({
        icon: 'error',
        title: 'File Error',
        text: 'Unable to read some files. Please try again.',
      });
    }
  };

  // 3) Step validation
  const validateCurrentStep = () => {
    switch (step) {
      case 1:
        return (
          !!formData.assessmentId &&
          !!formData.unitCode &&
          !!formData.unitTitle &&
          !!formData.level &&
          formData.assignmentBriefFiles.length > 0
        );
      case 2:
        return !!formData.assessmentId;
      case 3:
        return true;
      default:
        return true;
    }
  };

  // 4) Move to next step if valid
  const handleNext = () => {
    if (validateCurrentStep()) {
      setStep((prev) => Math.min(prev + 1, 4));
    } else {
      Swal.fire({
        title: 'Missing Fields',
        text: 'Please fill in all required fields before proceeding.',
        icon: 'warning',
        confirmButtonText: 'Okay',
      });
    }
  };

  // 5) Close & reset
  const close = () => {
    setFormData({
      assessmentId: '',
      unitCode: '',
      unitTitle: '',
      level: '',
      cohorts: [],
      centerNumber: '',
      centerName: '',
      releasedDate: '',
      lastModeration: new Date().toLocaleDateString(),
      dueDate: '',
      description: '',
      assignmentBriefFiles: [],
      assignmentCriteriaFiles: [],
      markers: [],
      learners: [],
    });
    setStep(1);
    onClose();
    setAssessment('');
    setIsPublished(false);
  };

  const finish = () => {
    setAssessment(formData.assessmentId);
    // Clear out formData, reset step
    setFormData({
      assessmentId: '',
      unitCode: '',
      unitTitle: '',
      level: '',
      cohorts: [],
      centerNumber: '',
      centerName: '',
      releasedDate: '',
      lastModeration: new Date().toLocaleDateString(),
      dueDate: '',
      description: '',
      assignmentBriefFiles: [],
      assignmentCriteriaFiles: [],
      markers: [],
      learners: [],
    });
    setStep(1);
  };

  // 6) Possibly delete recent assignment if needed
  const handleDeleteRecentAssignment = async () => {
    try {
      const response = await ApiRequest.delete(
        `${process.env.REACT_APP_EDUMARK_SYSTEMS_URL}/delete-recent-assignment/${formData.assessmentId}`,
        {
          data: {
            learners: formData.learners,
          },
        }
      );
      console.log('Delete response:', response);
    } catch (error) {
      console.error('Error deleting recent assignment:', error);
    }
  };

  // 7) Submit on final step
  const handleSubmit = async () => {
    if (submitting) return;
    setSubmitting(true);

    if (step === 4) {
      console.log('Handling Submit');
      // build the payload
      const createAssignmentPayload: any = {
        assessmentId: formData.assessmentId,
        unitCode: formData.unitCode,
        unitTitle: formData.unitTitle,
        level: formData.level,
        centerNumber: formData.centerNumber,
        centerName: formData.centerName,
        description: formData.description,
        cohorts: formData.cohorts,
        learners: formData.learners,
        assignmentBriefFiles: formData.assignmentBriefFiles, // now base64
        markers: formData.markers,
      };

      if (formData.releasedDate) {
        createAssignmentPayload.releasedDate = formData.releasedDate;
      }
      if (formData.lastModeration) {
        createAssignmentPayload.lastModeration = formData.lastModeration;
      }
      if (formData.dueDate) {
        createAssignmentPayload.dueDate = formData.dueDate;
      }
      if (formData.markers?.length > 0) {
        createAssignmentPayload.markers = formData.markers;
      }
      if (formData.assignmentBriefFiles?.length > 0) {
        createAssignmentPayload.assignmentBriefFile = formData.assignmentBriefFiles;
      }
      if (formData.assignmentCriteriaFiles?.length > 0) {
        createAssignmentPayload.assessmentCriteriaFile = formData.assignmentCriteriaFiles;
      }

      console.log('createAssignmentPayload:', createAssignmentPayload);

      try {
        console.log("createAssignmentPayload:", createAssignmentPayload)
        await axios.post(`${process.env.REACT_APP_EDUMARK_SYSTEMS_URL}/create-assignment`, createAssignmentPayload)
        await handleDeleteRecentAssignment();
        setIsPublished(true);
        setGetAssignment && setGetAssignment(true);
        finish();
      } catch (error) {
        console.error('Error creating assignment or deleting recent assignment:', error);
      } finally {
        setSubmitting(false);
      }
    } else {
      handleNext();
      setSubmitting(false);
    }
  };

  const toggleMaximize = () => {
    setIsMaximized((prev) => !prev);
  };

  const handleUnassignRef = useRef<{ handleUnassignAction: () => void }>(null);
  const handleassigningFunction = () => {
    if (handleUnassignRef.current) {
      handleUnassignRef.current.handleUnassignAction();
    }
  };

  return (
    <>
      <Modal
        show={isOpen && !isPublished}
        onHide={close}
        className={isMaximized ? 'maximized' : ''}
      >
        <Modal.Header>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {step > 1 && (
              <Button
                variant="link"
                onClick={() => setStep(step - 1)}
                style={{ padding: '10px', color: 'black' }}
              >
                <img src={ArrowLeftIcon} alt="" />
              </Button>
            )}
            <span style={{ flex: 1, fontWeight: 'bold' }}>
              {step === 1
                ? 'Create New Assignment'
                : step === 2
                ? 'Assign cohort'
                : step === 3
                ? 'Assign Marker'
                : step === 4
                ? 'Preview'
                : ''}
            </span>
            <Button
              variant="link"
              onClick={toggleMaximize}
              style={{ padding: '10px', color: 'black' }}
            >
              {isMaximized ? (
                <img src={CollapseIcon} alt="" />
              ) : (
                <img src={ExpandIcon} alt="" />
              )}
            </Button>
            <Button
              variant="link"
              onClick={() => {
                close();
              }}
              style={{ padding: '10px', color: 'black' }}
            >
              <img src={CrossIcon} alt="" />
            </Button>
          </div>
          <hr />
        </Modal.Header>
        <Modal.Body>
          {step === 1 && (
            <CreateAssignment
              formData={formData}
              handleChange={handleChange}
              handleFileChange={handleFileChange}
            />
          )}
          {step === 2 && (
            <AssignCohort
              formData={formData}
              handleChange={handleChange}
            />
          )}
          {step === 3 && (
            <AssignedMarker
              formdata={formData}
              ref={handleUnassignRef}
              setAnyAssigned={setAnyAssigned}
            />
          )}
          {step === 4 && (
            <CreateAssignment
              formData={formData}
              handleChange={handleChange}
              handleFileChange={handleFileChange}
              readOnly
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Row className="w-100">
            {step === 3 && (
              <Col>
                <Button
                  className={`text-primary  ${anyAssigned ? 'assigned-btn' : 'proceed-btn'}`}
                  style={{ background: 'none' }}
                  onClick={handleassigningFunction}
                >
                  Proceed Unassigned
                </Button>
              </Col>
            )}
            <Col className="d-flex">
              <Button
                variant="light"
                className="bg-none text-primary me-2 ms-auto"
                onClick={() => {
                  close();
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit} disabled={submitting}>
                {step === 4 ? 'Publish' : 'Next'}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      {isPublished && (
        <Modal show={isPublished} className="success-modal">
          <Row className="w-100">
            <Col className="col-md-12 text-end">
              <Button
                variant="link"
                onClick={() => {
                  close();
                  window.location.reload();
                }}
                style={{ padding: '10px', color: 'black' }}
              >
                <img src={CrossIcon} alt="" />
              </Button>
            </Col>
            <Col className="col-md-12 text-center">
              <img src={CommonFileCheckIcon} alt="" className="cstm-img" />
            </Col>
            <Col className="col-md-12 text-center ctsm-span-col mb-5">
              <span>You have successfully created a new assignment</span>
              <br />
              <span>{assessment}</span>
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
};

export default CreateNewAssignment;
