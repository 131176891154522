import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { toast } from "react-toastify";
import Loader from "@/app/components/Loading";
import PaginatedTable from "@/app/components/Table/PaginatedTable";
import FilterDropdown from "@/app/components/Table/TableFilter";
import TableActionDropdown from "@/app/components/Table/TableAction";
import UploadAssignment from "@/app/components/UploadAssignment";
import Layout from "@components/Layout";
import Button from "@components/Button";
import ApiRequest from "@/app/api/ApiRequest";
import COLORS from "@/app/utils/Colors";
import IMAGES from "@utils/Images";
import AssignmentList from "@components/AssignmentList/AssignmentList";
import PaginationButton from "@components/Pagination";
import {
  learnerActiveOptions,
  learnerPastOptions,
  sortOptions,
  learnerHeadings,
} from "@/app/utils/helper";
import { formatDateToMonthDateYear } from "@/app/modules/helpers/FormikHelper";
import { RootState } from "@/app/redux/store";
import "./Assignment.module.style.css";

const LearnerAssignment: React.FC = () => {
  const [activeTab, setActiveTab] = useState("Active");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState<string>("");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [openUpload, setOpenUpload] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<(string | number)[] | string>(""); 
  const [isFilterStatus, setIsFilterStatus] = useState(false);
  const [isSortStatus, setIsSortStatus] = useState(false);
  const [learnerAssignments, setLearnerAssignments] = useState<any[]>([]);
  const { user} = useSelector((state: RootState) => state.auth);

  console.log("learnerAssignments:", learnerAssignments)


  // Redux State
  const { LearnerAssignments, totalPages, loading, error } = useSelector(
    (state: RootState) => state.assignment
  );
  // Columns for PaginatedTable
  const columns = [
    { header: "Assignment ID", accessorKey: "assessmentId" },
    {
      header: "Unit Title",
      cell: ({ row }: any) => (
        <span style={{ color: COLORS.GRAY }}>{row.original.unitTitle}</span>
      ),
    },
    {
      header: "Released Date",
      cell: ({ row }: any) => (
        <span style={{ color: COLORS.GRAY }}>
          {formatDateToMonthDateYear(row.original.releasedDate)}
        </span>
      ),
    },
    {
      header: "Status",
      cell: ({ row }: any) => (
        <div
          className={`d-flex align-items-center gap-2 ${
            row.original.status === "New"
              ? "label-success"
              : row.original.status === "Resubmission"
              ? "label-danger"
              : "label-warning"
          }`}
        >
          <span className="border rounded-circle p-1" />
          <label className="item">{row.original.status}</label>
        </div>
      ),
    },
    {
      header: "Actions",
      cell: ({ row }: any) => (
        <TableActionDropdown
          actions={[
            {
              label: "Download Assignment",
              onClick: () =>
                handleDownload(row.original.assessmentId, "AssignmentBrief"),
            },
            {
              label: "Download Criteria",
              onClick: () =>
                handleDownload(row.original.assessmentId, "AssignmentCriteria"),
            },
            { label: "View Details", onClick: () => handleViewDetails(row.original) },
          ]}
        />
      ),
    },
  ];

  function handleDownload(assessmentId, fileId) {
    console.log('handling download LA')
    const link = document.createElement("a");
    link.href = `${process.env.REACT_APP_EDUMARK_SYSTEMS_URL}/assignments/${assessmentId}/file/${fileId}`;
    link.download = fileId; // or something else like "myFile.docx"
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  const handleViewDetails = (item: any) => {
    setSelectedItem(item);
    setOpenUpload(true);
  };
  //note//
  const fetchLearnerAssignments = async (
    pageIndex: number,
    pageSize: number,
    query: string
  ) => {
    const learnerId = user.id; // Replace with dynamic learnerId
    const payload = {
      page: pageIndex,
      limit: pageSize,
      search: query,
      learnerId,
      type: activeTab,
      filter,
      sortBy,
    };

    try {
      const response = await ApiRequest.get(
        `${process.env.REACT_APP_EDUMARK_SYSTEMS_URL}/learner-assignments`,
        { params: payload }
      );
      console.log("Assignments fetched successfully:", response);
      setLearnerAssignments(Array(response)); // Update the main data state
      return response; // Return data for PaginatedTable
    } catch (err) {
      console.error("Error fetching assignments:", err);
    }
  };

  useEffect(() => {
    fetchLearnerAssignments(currentPage, pageSize, searchQuery);
  }, [currentPage, pageSize, searchQuery, activeTab, filter, sortBy]);

  const handleSearchChange = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value);
    },
    500
  );

  const handlePaginationChange = (page: number, size: number) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    setCurrentPage(1); // Reset to the first page when switching tabs
  };

  const handleSortData = (value: string | number) => {
    setSortBy(String(value)); // Convert to string
  };

const handleFilterData = (filters: (string | number)[]) => {
    setFilter(filters); // Update your filter state
};

useEffect(() => {
    console.log("Updated learnerAssignments:", learnerAssignments);
}, learnerAssignments);

return (
    <Layout>
      {loading && <Loader />}
      <div className="assignment-container m-4 vh-100">
        <div className="sticky-top z-3 bg-white">
          <div className="heading-container mb-4">
            <span className="heading">Assignments</span>
            <span className="label">Manage course assignments</span>
          </div>
          <div className="buttons d-flex flex-lg-row flex-column gap-lg-4 gap-2 mt-lg-0 mt-2 mb-3">
            <Button
              label="Active"
              className={`tab-btn ${activeTab === "Active" ? "active-btn" : ""}`}
              onClick={() => handleTabChange("Active")}
            />
            <Button
              label="Past"
              className={`tab-btn ${activeTab === "Past" ? "active-btn" : ""}`}
              onClick={() => handleTabChange("Past")}
            />
          </div>
          {learnerAssignments && learnerAssignments.length > 0 ? (
            <>
              <AssignmentList
                data={learnerAssignments[0]
                  .reduce((acc, current) => {
                    // Check for duplicates using 'unitTitle'
                    const isDuplicate = acc.some(item => item.unitTitle === current.unitTitle.trim());
                    if (!isDuplicate) {
                      acc.push(current);
                    }
                    console.log("acc: ", acc)
                    return acc;
                  }, [])
                  .map(item => ({
                    assessmentId: item.id,
                    title: item.unitTitle.trim(),
                    date: item.releasedDate
                      ? new Date(item.releasedDate).toLocaleDateString()
                      : "N/A",
                    label: item.status || "N/A",
                    assignmentBriefFile: item.assignmentBriefFile || "",
                    assessmentCriteriaFile: item.assessmentCriteriaFile || "",
                    lastModerationDate: item.lastModerationDate || "",
                    markers: item.markers || [],
                    code: item.code || "N/A",
                    description: item.description || "No description available",
                  }))} //add status assignmentstatus for resubmissions
                onViewDetails={handleViewDetails}
              />
              <div className="pagination-section my-4 d-flex flex-lg-row flex-column gap-lg-0">
                <div className="col-lg-4 col-12">
                  <div className="d-flex align-items-center gap-2 page-dropdown">
                    <label>Show</label>
                    <select
                      name="number"
                      id="number"
                      className="page-dropdown"
                      onChange={(e) => handlePaginationChange(Number(e.target.value), pageSize)}
                    >
                      <option value="5">5</option>
                      <option value="10" selected>
                        10
                      </option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                    <label htmlFor="">Row</label>
                  </div>
                </div>
                <div className="col-lg-4 col-12 mt-lg-0 mt-2">
                  <div className="flex justify-center">
                    <PaginationButton
                      count={totalPages}
                      page={currentPage}
                      changePage={(e, page) => handlePaginationChange(page, pageSize)}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div>No Records Found.</div>
          )}
        </div>
      </div>
      {openUpload && (
        <UploadAssignment
          isOpen={openUpload}
          item={selectedItem}
          onClose={() => setOpenUpload(false)}
        />
      )}
    </Layout>
  );
  
};

export default LearnerAssignment;
