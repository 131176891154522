import { UploadIcon } from "@/app/assets/icons/svg";
import Loader from "@/app/components/Loading";
import PaginatedTable from "@/app/components/Table/PaginatedTable";
import TableActionDropdown from "@/app/components/Table/TableAction";
import FilterDropdown from "@/app/components/Table/TableFilter";
import { formatDateToMonthDateYear } from "@/app/modules/helpers/FormikHelper";
import { FETCH_ASSIGNMENTS } from "@/app/redux/actionTypes";
import { RootState } from "@/app/redux/store";
import COLORS from "@/app/utils/Colors";
import { activeEductorsOptions, headingsForEducator, sortOptions } from "@/app/utils/helper";
import Button from "@components/Button";
import Layout from "@components/Layout";
import IMAGES from "@utils/Images";
import debounce from "lodash.debounce";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "./Assignment.module.style.css";
import CreateNewAssignment from "@/app/components/CreateAssignment";
import { apiUrl } from "@/app/api/ApiRequest";

interface AssignmentsProps {
    userType?: string
}
const SupervisorAssignment: React.FC<AssignmentsProps> = () => {
    const [isFilterStatus, setIsFilterStatus] = useState(false);
    const [filter, setFilter] = useState<any>("");
    const { "Level": filterLevel, "Assignment Status": filterStatus } = filter;
    const [sortBy, setSortBy] = useState<any>("");
    const [pages, setPages] = useState<number>(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [pageSize, setPageSize] = useState<number>(10);
    const [isSortStatus, setIsSortStatus] = useState(false);
    const { assignments, totalPages, loading, error } = useSelector((state: RootState) => state.assignment);
    const [openCreate, setOpenCreate] = useState(false);
    const [getAssignment, setGetAssignment] = useState<boolean>(false);
    console.log("🚀 ~ getAssignment:", getAssignment)



    const handleDownload = (assessmentId: string, fileName: string) => {
        console.log('handling download SA')
        const link = document.createElement('a');
        link.href = `${apiUrl}assignment/download-assignment/${assessmentId}/${fileName}`;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const columns = [
        { header: 'Assignment Id', accessorKey: 'assessmentId' },
        { header: 'Unit Title', cell: ({ row }) => <span style={{ color: COLORS.GRAY }}>{row.original.unitTitle}</span> },
        { header: 'Released Date', cell: ({ row }) => <span style={{ color: COLORS.GRAY }}>{formatDateToMonthDateYear(row.original.releasedDate)}</span> },
        {
            header: 'Status', cell: ({ row }) =>
                <div
                    className={`d-flex align-items-center gap-2  ${row.original.status === "Done"
                        ? "label-success"
                        : row.original.status === "Pending"
                            ? "label-warning"
                            : "label-danger"
                        }`}
                >
                    <span className="border rounded-circle p-1" />
                    <label className="item">{row.original.status}</label>
                </div>
        },
        {
            header: 'Actions', cell: ({ row }) => (
                <TableActionDropdown
                    actions={[
                        { label: 'Download Assignment', onClick: () => handleDownload(row.original.assessmentId, 'AssignmentBrief') },
                        { label: 'Download Criteria', onClick: () => handleDownload(row.original.assessmentId, 'AssignmentCriteria') },
                    ]}
                />
            )
        },
    ];

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error])

    const handleStatusFilter = (status: (string | number)[]) => {
        setFilter(status);
    };

    const handleSortData = (status: string | number) => {
        setSortBy(status);
    };

    const dispatch = useDispatch()

    const fetchAssignments = (page: number, limit: number, search: string, filterLevel?: [], filterStatus?: [], sortBy?: string) => {
        dispatch({
            type: FETCH_ASSIGNMENTS,
            payload: { page, limit, search, filterLevel, filterStatus, sortBy },
        });
    };

    useEffect(() => {
        fetchAssignments(pages, pageSize, searchQuery, filterLevel, filterStatus, sortBy);
    }, [pages, pageSize, searchQuery, filter, sortBy, getAssignment]);

    const handleSearchChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    }, 500);

    const handlePaginationChange = (pageNumber: number, pageSize: number) => {
        setPages(pageNumber);
        setPageSize(pageSize);
    };

    return (
        <Layout>
            {loading && <Loader />}
            <div className="assignment-container m-4 vh-100">
                <div className="sticky-top z-3 bg-white">
                    <div className="heading-container mb-4">
                        <span className="heading">Assignments</span>
                        <span className="label">Manage course assignments</span>
                    </div>
                    <PaginatedTable
                        columns={columns}
                        data={assignments || []}
                        pageCount={totalPages || 2}
                        fetchData={fetchAssignments}
                        handlePaginationChange={handlePaginationChange}
                        handleSearchChange={handleSearchChange}
                        customButtons={
                            <div className="d-flex gap-2">
                                <FilterDropdown
                                    type="sort"
                                    options={sortOptions}
                                    onSortSelect={handleSortData}
                                    placeholder={
                                        <Button
                                            label={""}
                                            className={"assignment-btn"}
                                            onClick={() => {
                                                setIsSortStatus(!isSortStatus);
                                            }}
                                            isIcon={true}
                                            icon={IMAGES.SORT_ICON}
                                        />
                                    }
                                />
                                <FilterDropdown
                                    type="filter"
                                    options={activeEductorsOptions}
                                    headings={headingsForEducator}
                                    onFilterSelect={handleStatusFilter}
                                    isMultiple={true}
                                    placeholder={
                                        <Button
                                            label={"Filter"}
                                            className={"assignment-btn"}
                                            onClick={() => {
                                                setIsFilterStatus(!isFilterStatus);
                                            }}
                                            isIcon={true}
                                            icon={IMAGES.FILTER}
                                        />
                                    }
                                />
                                <Button
                                    label={"Create Assignment"}
                                    className={"assignment-btn primary-btn"}
                                    onClick={() => {
                                        setOpenCreate(!openCreate);
                                    }}
                                    isIcon={true}
                                    icon={IMAGES.PLUS}
                                />
                            </div>
                        }
                    />
                </div>
                {openCreate && (
                    <CreateNewAssignment
                        isOpen={openCreate}
                        onClose={() => setOpenCreate((prev) => !prev)}
                        setGetAssignment={setGetAssignment}
                    />
                )}
            </div>
        </Layout>
    );
};

export default SupervisorAssignment;
