// educatorassignment.tsx
import { apiUrl } from "@/app/api/ApiRequest";
import Loader from "@/app/components/Loading";
import PaginatedTable from "@/app/components/Table/PaginatedTable";
import TableActionDropdown from "@/app/components/Table/TableAction";
import FilterDropdown from "@/app/components/Table/TableFilter";
import { formatDateToMonthDateYear } from "@/app/modules/helpers/FormikHelper";
import COLORS from "@/app/utils/Colors";
import { activeEductorsOptions, headingsForEducator, sortOptions } from "@/app/utils/helper";
import Button from "@components/Button";
import Layout from "@components/Layout";
import IMAGES from "@utils/Images";
import debounce from "lodash.debounce";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./Assignment.module.style.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/app/redux/store";


interface AssignmentsProps {
    userType?: string
}

const EducatorAssignment: React.FC<AssignmentsProps> = () => {
    const { user} = useSelector((state: RootState) => state.auth);
    const [isFilterStatus, setIsFilterStatus] = useState(false);
    const [filter, setFilter] = useState<any>("");
    const { "Level": filterLevel, "Assignment Status": filterStatus } = filter;
    const [sortBy, setSortBy] = useState<any>("");
    const [pages, setPages] = useState<number>(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [pageSize, setPageSize] = useState<number>(10);
    const [isSortStatus, setIsSortStatus] = useState(false);

    const [assignments, setAssignments] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const columns = [
        { header: 'Assignment Id', accessorKey: 'assessmentId' },
        {
            header: 'Unit Title',
            cell: ({ row }) => (
                <span style={{ color: COLORS.GRAY }}>{row.original.unitTitle}</span>
            ),
        },
        {
            header: 'Released Date',
            cell: ({ row }) => (
                <span style={{ color: COLORS.GRAY }}>
                    {formatDateToMonthDateYear(row.original.releasedDate)}
                </span>
            ),
        },
        {
            header: 'Status',
            cell: ({ row }) => (
                <div
                    className={`d-flex align-items-center gap-2 ${
                        row.original.status === "Assigned"
                            ? "label-primary"
                            : row.original.status === "Unassigned"
                            ? "label-success"
                            : "label-primary"
                    }`}
                >
                    <span className="border rounded-circle p-1" />
                    <label className="item">{row.original.status}</label>
                </div>
            ),
        },
        {
            header: 'Actions', 
            cell: ({ row }) => (
                <TableActionDropdown
                    actions={
                        row.original.status === "Unassigned"
                            ? [
                                {
                                    label: 'Claim Assignment',
                                    onClick: () => handleClaimAssignment(row.original.id),
                                },
                                {
                                    label: 'Download Assignment',
                                    onClick: () => handleDownload(row.original.id, "AssignmentBrief"),
                                },
                            ]
                            : [
                                {
                                    label: 'Download Assignment',
                                    onClick: () => handleDownload(row.original.id, "AssignmentBrief"),
                                },
                            ]
                    }
                />
            ),
        },
    ];

    const fetchAssignments = async (
        page: number,
        limit: number,
        search: string = "",
        filterLevel: string = "",
        filterStatus: string = "",
        sortBy: string = ""
    ) => {
        try {
            setLoading(true);
            setError("");
            const markerId = user.id; // Replace with dynamic learnerId
            console.log("markerId:", markerId)
            const response = await axios.get(
                `${process.env.REACT_APP_EDUMARK_SYSTEMS_URL}/learner-assignments`,
                {
                    params: {
                        page,
                        limit,
                        search,
                        markerId,
                        filterLevel,
                        filterStatus,
                        sortBy,
                        
                    },
                }
            );
            const data = response.data || [];
            setAssignments(
                data.map((item: any) => ({
                    id: item.id,
                    assessmentId: item.id,
                    unitTitle: item.unitTitle,
                    releasedDate: item.releasedDate,
                    status: item.status,
                    markingId: item.markingId
                }))
            );

            // If the API returns total count, calculate totalPages. 
            // If not, assume data.length < limit means last page.
            // Modify if API provides total count or total pages directly.
            const totalItems = data.length < limit && page === 1 ? data.length : page * limit + data.length;
            // For demonstration, we assume no total count, just keep incrementing until no more items.
            // Ideally, your API should return total count or total pages.
            // Here, let's assume at least 2 pages as a fallback or better logic if API provides total count.
            setTotalPages(data.length < limit && page > 1 ? page : page + 1);
        } catch (err: any) {
            setError("Failed to fetch assignments");
            toast.error("Failed to fetch assignments");
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        fetchAssignments(pages, pageSize, searchQuery, filterLevel, filterStatus, sortBy);
    }, [pages, pageSize, searchQuery, filter, sortBy]);

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);

    const handleStatusFilter = (status: (string | number)[]) => {
        setFilter(status);
    };

    const handleSortData = (status: string | number) => {
        setSortBy(status);
    };

    const handleSearchChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    }, 500);

    const handlePaginationChange = (pageNumber: number, pageSize: number) => {
        setPages(pageNumber);
        setPageSize(pageSize);
    };

    const handleDownload = (assessmentId: string, fileName: string) => {
        console.log('handling download EA')
        const link = document.createElement('a');
        link.href = `${apiUrl}assignment/download-assignment/${assessmentId}/${fileName}`;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleClaimAssignment = async (assignmentId: string) => {
        try {
            // Example endpoint for claiming assignment. Adjust as needed.
            await axios.post(`${process.env.REACT_APP_EDUMARK_SYSTEMS_URL}/assignments/claim`, {
                assignmentId,
            });
            toast.success("Assignment claimed successfully!");
            // Refresh assignments
            fetchAssignments(pages, pageSize, searchQuery, filterLevel, filterStatus, sortBy);
        } catch (err) {
            toast.error("Failed to claim assignment");
        }
    };
    console.log("assignments (maybe no status):", assignments)
    return (
        <Layout>
            {loading && <Loader />}
            <div className="assignment-container m-4 vh-100">
                <div className="sticky-top z-3 bg-white">
                    <div className="heading-container mb-4">
                        <span className="heading">Assignments</span>
                        <span className="label">Manage course assignments</span>
                    </div>
                    <PaginatedTable
                        columns={columns}
                        data={assignments || []}
                        pageCount={totalPages || 2}
                        fetchData={fetchAssignments}
                        handlePaginationChange={handlePaginationChange}
                        handleSearchChange={handleSearchChange}
                        customButtons={
                            <div className="d-flex gap-2">
                                <FilterDropdown
                                    type="sort"
                                    options={sortOptions}
                                    onSortSelect={handleSortData}
                                    placeholder={
                                        <Button
                                            label={""}
                                            className={"assignment-btn"}
                                            onClick={() => {
                                                setIsSortStatus(!isSortStatus);
                                            }}
                                            isIcon={true}
                                            icon={IMAGES.SORT_ICON}
                                        />
                                    }
                                />
                                <FilterDropdown
                                    type="filter"
                                    options={activeEductorsOptions}
                                    headings={headingsForEducator}
                                    onFilterSelect={handleStatusFilter}
                                    isMultiple={true}
                                    placeholder={
                                        <Button
                                            label={"Filter"}
                                            className={"assignment-btn"}
                                            onClick={() => {
                                                setIsFilterStatus(!isFilterStatus);
                                            }}
                                            isIcon={true}
                                            icon={IMAGES.FILTER}
                                        />
                                    }
                                />
                            </div>
                        }
                    />
                </div>
            </div>
        </Layout>
    );
};

export default EducatorAssignment;