import IMAGES from "@utils/Images";
import React, { useState } from "react";
import { Button, Col, Dropdown, Modal, Row } from "react-bootstrap";
import "./style.css";
import { useSelector } from "react-redux";
import { RootState } from "@/app/redux/store";
import { CommonFileCheckIcon, CrossIcon } from "@/app/assets/icons/svg";

type Marker = {
  id: string;
  name: string;
  levels: number[];
  avatar: string;
};

export type ListItemType = {
  assessmentId: string;
  code: string;
  title: string;
  date: string;
  label: string;
  description: string;
  // Each is presumably an array with { filename, mimeType, content }
  assignmentBriefFile: any;
  assessmentCriteriaFile: any;
  lastModerationDate: string;
  markers: Marker[];
};

interface AssignmentListProps {
  data: ListItemType[];
  activeTab?: string | "active";
  onViewDetails?: (item?: ListItemType) => void;
}

const AssignmentList: React.FC<AssignmentListProps> = ({
  data,
  activeTab,
  onViewDetails,
}) => {
  console.log("data AssignmentList: ", data);

  const { userType } = useSelector((state: RootState) => state.auth);
  const [isAssigned, setIsAssigned] = useState(false);
  const [isAssignedAssessment, setIsAssignedAssessment] = useState("");
  const [openDropdownId, setOpenDropdownId] = useState<number | null>(null);

  const handleToggle = (id: number) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };

  // "Claim" logic for educators
  const handleAssignEducator = (index: number) => {
    data[index].label = "Unassigned";
    setIsAssigned(true);
    setIsAssignedAssessment(data[index].assessmentId);
  };

  const onClose = () => {
    setIsAssigned(false);
    setIsAssignedAssessment("");
  };

  /** 
   * Single `handleDownload(assessmentId, fileId)`,
   * just like the other file. 
   */
  const handleDownload = (assessmentId: string, fileId: string) => {
    console.log("handling download - single method:", { assessmentId, fileId });
    const link = document.createElement("a");
    link.href = `${process.env.REACT_APP_EDUMARK_SYSTEMS_URL}/assignments/${assessmentId}/file/${encodeURIComponent(fileId)}`;
    link.download = fileId; // sets the default filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  /**
   * figure out which fileId to pass: 
   * e.g. use item.assignmentBriefFile[0].filename or 
   * item.assessmentCriteriaFile[0].filename
   */
  const onDownloadClick = (fileType: string, index: number) => {
    const item = data[index];
    let fileArray: any[] | undefined;
    if (fileType === "assignment") {
      fileArray = item.assignmentBriefFile;
    } else if (fileType === "criteria") {
      fileArray = item.assessmentCriteriaFile;
    } else {
      alert("Unknown file type");
      return;
    }

    if (!fileArray || fileArray.length === 0) {
      alert(`No ${fileType} file found!`);
      return;
    }
    const fileName = fileArray[0].filename || `myFile_${item.assessmentId}.docx`;

    // Now call the single `handleDownload` with the needed info
    handleDownload(item.assessmentId, fileName);
  };

  const handleView = (item: ListItemType) => {
    console.log("Viewing assignment:", item);
    // Additional logic or pass to parent
  };

  // Simple delete logic for supervisors/admin
  const handleDelete = async (item: ListItemType) => {
    console.log("Deleting assignment:", item);
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the assignment: ${item.title}?`
    );
    if (!confirmDelete) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_EDUMARK_SYSTEMS_URL}/delete-assignment/${item.assessmentId}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error deleting assignment:", errorData.detail);
        alert(`Failed to delete assignment: ${errorData.detail}`);
        return;
      }
      alert(`Deleted assignment: ${item.title}`);
      window.location.reload();
    } catch (error) {
      console.error("Error deleting assignment:", error);
      alert(
        "An error occurred while deleting the assignment. Please try again."
      );
    }
  };

  return (
    <div className="table-container z-2">
      <table className="assignment-table">
        <thead className="sticky-top z-1">
          <tr>
            <th scope="col" className="table-heading first-child">
              Assignment ID
            </th>
            <th scope="col" className="table-heading">Unit Title</th>
            <th scope="col" className="table-heading">Released Date</th>
            <th scope="col" className="table-heading">Status</th>
            <th scope="col" className="table-heading">Actions</th>
          </tr>
        </thead>
        <tbody className="z-0">
          {data?.map((item, index) => (
            <tr key={index}>
              <td scope="row" className="table-cell first-child">
                {item.assessmentId}
              </td>
              <td className="title-column table-cell">{item.title}</td>
              <td className="table-cell">{item.date}</td>
              <td className="table-cell">
                {/* Supervisor/Admin */}
                {(userType === "supervisor" || userType === "admin") && (
                  <div
                    className={`d-flex align-items-center gap-2 ${
                      item.label === "Done"
                        ? "label-success"
                        : item.label === "Pending"
                        ? "label-danger"
                        : "label-primary"
                    }`}
                  >
                    <span className="border rounded-circle p-1" />
                    <label className="item">{item.label}</label>
                  </div>
                )}

                {/* Educator */}
                {userType === "educator" && (
                  <div
                    className={`d-flex align-items-center gap-2 ${
                      item.label === "Unassigned" ? "label-success" : "label-primary"
                    }`}
                  >
                    <span className="border rounded-circle p-1" />
                    <label className="item">{item.label}</label>
                  </div>
                )}

                {/* Learner: Active */}
                {userType === "learner" && activeTab === "active" && (
                  <div
                    className={`d-flex align-items-center gap-2  ${
                      item.label === "New"
                        ? "label-success"
                        : item.label === "Resubmission"
                        ? "label-danger"
                        : "label-primary"
                    }`}
                  >
                    <span className="border rounded-circle p-1" />
                    <label className="item">{item.label}</label>
                  </div>
                )}

                {/* Learner: Past */}
                {userType === "learner" && activeTab === "past" && (
                  <div
                    className={`d-flex align-items-center gap-2  ${
                      item.label === "Under Review"
                        ? "label-success"
                        : item.label === "Fail"
                        ? "label-danger"
                        : item.label === "Resubmission"
                        ? "label-warning"
                        : "label-primary"
                    }`}
                  >
                    <span className="border rounded-circle p-1" />
                    <label className="item">{item.label}</label>
                  </div>
                )}
              </td>
              <td className="table-cell">
                {(userType === "supervisor" || userType === "admin") && (
                  <Dropdown
                    id={`id${index}`}
                    show={openDropdownId === index}
                    onToggle={() => handleToggle(index)}
                  >
                    <Dropdown.Toggle
                      as="img"
                      src={IMAGES.ACTION}
                      alt="Menu Icon"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleToggle(index)}
                    />
                    <Dropdown.Menu className="assignment-actions-dropdown z-4">
                      <Dropdown.Item onClick={() => handleView(item)}>
                        View assignment
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleDelete(item)}>
                        Delete assignment
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                {userType === "educator" && (
                  <Dropdown
                    id={`id${index}`}
                    show={openDropdownId === index}
                    onToggle={() => handleToggle(index)}
                  >
                    <Dropdown.Toggle
                      as="img"
                      src={IMAGES.ACTION}
                      alt="Menu Icon"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleToggle(index)}
                    />
                    <Dropdown.Menu className="assignment-actions-dropdown z-4">
                      <Dropdown.Item onClick={() => handleAssignEducator(index)}>
                        Claim Assignment
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => onDownloadClick("assignment", index)}
                      >
                        Download Assignment
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                {userType === "learner" && (
                  <Dropdown
                    id={`id${index}`}
                    show={openDropdownId === index}
                    onToggle={() => handleToggle(index)}
                  >
                    <Dropdown.Toggle
                      as="img"
                      src={IMAGES.ACTION}
                      alt="Menu Icon"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleToggle(index)}
                    />
                    <Dropdown.Menu className="assignment-actions-dropdown z-4">
                      <Dropdown.Item
                        onClick={() => onDownloadClick("assignment", index)}
                      >
                        Download Assignment
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => onDownloadClick("criteria", index)}
                      >
                        Download Criteria
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => onViewDetails?.(item)}>
                        View Details
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* "Claim" success modal */}
      <Modal show={isAssigned} className="success-modal">
        <Row className="w-100">
          <Col className="col-md-12 text-end">
            <Button
              variant="link"
              onClick={() => {
                onClose();
              }}
              style={{ padding: "10px", color: "black" }}
            >
              <img src={CrossIcon} alt="CrossIcon" />
            </Button>
          </Col>
          <Col className="col-md-12 text-center">
            <img
              src={CommonFileCheckIcon}
              alt="CommonFileCheckIcon"
              className="cstm-img"
            />
          </Col>
          <Col className="col-md-12 text-center ctsm-span-col mb-5">
            <span>Assessment for assignment ID {isAssignedAssessment}</span>
            <br />
            <span>Is assigned to you</span>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default AssignmentList;
