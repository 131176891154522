import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _USER_CLEAR_SUCCESS, ACT_DEACTIVATE_USER, DELETE_USER, FETCH_USERS, RESEND_CREDENTIALS } from '@/app/redux/actionTypes';
import { RootState } from '@/app/redux/store';
import PaginatedTable from '@components/Table/PaginatedTable';
import Loader from '@/app/components/Loading';
import { debounce } from 'lodash';
import { User } from '@/app/modules/types/types';
import { formatDateToMonthDateYear, formatLevelsWithDetails } from '@/app/modules/helpers/FormikHelper';
import Switch from '@mui/material/Switch';
import { toast } from 'react-toastify';
import TableActionDropdown from '@/app/components/Table/TableAction';
import { successPopup } from '@/app/components/SuccessModal';
import { confirmationModal } from '@/app/components/ConfirmationModal';
import UserDetailsPopUp from '@/app/components/UserDetailsPopUp/UserDetailsPopUp';

interface EducatorTableProps {
    selectedSort: string
    selectedFilterUserStatus: string[]
    selectedFilterLevels: string[];
}
const EducatorTable: React.FC<EducatorTableProps> = ({ selectedSort, selectedFilterUserStatus, selectedFilterLevels }) => {

    const dispatch = useDispatch();

    const [selectedEducator, setSelectedEducator] = useState<User>()
    const [pages, setPages] = useState<number>(1);
    const [searchQuery, setSearchQuery] = useState("");

    const [pageSize, setPageSize] = useState<number>(10);

    const { users, pagination, loading, error, success } = useSelector((state: RootState) => state.user);

    const [detailModal, setDetailModal] = useState(false)

    const fetchData = async (
        page: number,
        rows: number,
        sortBy: string = '',
        query: string = '',
        filterUserStatus: string[] = [],
        filterLevels: string[] = []
    ) => {
        const formattedFilterUserStatus = Array.isArray(filterUserStatus) ? JSON.stringify(filterUserStatus) : JSON.stringify([]);
        const formattedFilterLevels = Array.isArray(filterLevels) ? JSON.stringify(filterLevels) : JSON.stringify([]);
        dispatch({
            type: FETCH_USERS, payload: {
                page,
                limit: rows,
                search: query,
                userType: 'educator',
                sortBy,
                filterUserStatus: formattedFilterUserStatus,
                filterLevels: formattedFilterLevels
            }
        });
    };



    useEffect(() => {
        fetchData(pages, pageSize, selectedSort, searchQuery, selectedFilterUserStatus, selectedFilterLevels);
    }, [pages, pageSize, searchQuery, selectedSort, selectedFilterUserStatus, selectedFilterLevels]);


    const AssignmentAccessSwitch = ({ access, id }) => {
        return (<div>
            <Switch
                id={id}
                checked={access}
            />
        </div>
        )
    }

    const deactivateUser = async (row) => {
        console.log(row.original.id)
        const result = await confirmationModal(`Are you sure you want to ${row.original.activate ? 'deactivate' : 'activate'} the user`);
        if (result) {
            dispatch({ type: ACT_DEACTIVATE_USER, payload: { userId: row.original.id, type: row.original.activate ? 'deactivate' : 'activate' } })
        }
    }

    const deleteUser = async (row) => {
        const result = await confirmationModal(`Are you sure you want to delete the user`);
        if (result) {
            dispatch({ type: DELETE_USER, payload: { userId: row.original.id } })
        }
    }

    const handleSuccess = async (message: string) => {
        await successPopup(message);
        dispatch({ type: _USER_CLEAR_SUCCESS })
        // Pass success message here
    };

    useEffect(() => {
        if (success) {
            handleSuccess(success);
        }
    }, [success]);




    const columns = [
        { header: 'Name', accessorKey: 'name' },
        { header: 'Joining Date', cell: ({ row }) => <span>{formatDateToMonthDateYear(row.original.enrolmentDate)}</span> },
        { header: 'Level', cell: ({ row }) => <span>{formatLevelsWithDetails(row.original.levels)}</span> },
        { header: 'Assignment Access', cell: ({ row }) => <AssignmentAccessSwitch access={row.original.accessToAssignments} id={row.original.id} /> },
        {
            header: 'Actions', cell: ({ row }) => (
                <TableActionDropdown
                    actions={[
                        {
                            label: 'View Details', onClick: () => {
                                console.log(row.original)
                                setSelectedEducator(row.original)
                                setDetailModal(true)
                            }
                        },
                        { label: row.original.activate ? 'Deactivate User' : 'Activate User', onClick: () => deactivateUser(row) },
                        { label: 'Resend login credentials', onClick: () => dispatch({ type: RESEND_CREDENTIALS, payload: { user: row.original.id } }) },
                        {
                            label: 'Delete User',
                            onClick: async () => {
                              await deleteUser(row); 
                              window.location.reload();
                            }
                          },
                    ]}
                />
            )
        },
    ];

    const handleSearchChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    }, 500);

    const handlePaginationChange = (pageNumber: number, pageSize: number) => {
        setPages(pageNumber);
        setPageSize(pageSize);
    };

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);

    console.log("educator fetchData:", fetchData)
    console.log('users:', users)
    console.log('pagination:', pagination)
    return (
        <>
            {loading && <Loader />}
            {users && pagination && <PaginatedTable<User>
                columns={columns}
                data={users}
                pageCount={pagination?.totalPages}
                fetchData={fetchData}
                handleSearchChange={handleSearchChange}
                handlePaginationChange={handlePaginationChange}
            />}
            {selectedEducator && detailModal && <UserDetailsPopUp
                selectedUser={selectedEducator} visible={detailModal} handleClose={() => {
                    setDetailModal(false)
                }} />}
        </>
    );
};

export default EducatorTable;
