import { DownloadIcon } from "@/app/assets/icons/svg";
import { Col, Button } from "react-bootstrap";
import FileCard from "./Cards/FileCards";
import MarkerCard from "./Cards/MarkerCard";
import { useEffect, useState } from "react";
import { apiUrl } from "@/app/api/ApiRequest";

type marker = {
    id: string,
    name: string,
    levels: number[],
    avatar: string
}
type assignment = {
    assessmentId: string,
    code: string,
    title: string,
    date: string,
    label: string,
    description?: string,
    assignmentBriefFile: string,
    assessmentCriteriaFile: string,
    lastModerationDate: string,
    markers: marker[]
}
interface ViewAssignmentProps {
    data: assignment;
}
const ViewAssignment: React.FC<ViewAssignmentProps> = ({
    data
}) => {
    const handleDownload = (assessmentId: string, fileName: string) => {
        console.log('handling download VA')
        const link = document.createElement('a');
        link.href = `${apiUrl}assignment/download-assignment/${assessmentId}/${fileName}`;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    console.log(data)

    const [buttonText, setButtonText] = useState({
        assignment: 'Download Assignment',
        criteria: 'Assessment Criteria',
    });

    const updateButtonText = () => {
        if (window.innerWidth <= 768) {
            setButtonText({
                assignment: 'Assignment',
                criteria: 'Criteria',
            });
        } else {
            setButtonText({
                assignment: 'Download Assignment',
                criteria: 'Assessment Criteria',
            });
        }
    };

    useEffect(() => {
        updateButtonText();
        window.addEventListener('resize', updateButtonText);
        return () => {
            window.removeEventListener('resize', updateButtonText);
        };
    }, []);

    return (
        <>
            <div className="col flotting-btn">
                <Button onClick={() => { handleDownload(data.assessmentId, "AssignmentBrief"); }} className="download-button">
                    <img src={DownloadIcon} alt="" width="30px" /> {buttonText.assignment}
                </Button>
                <Button onClick={() => { handleDownload(data.assessmentId, "AssignmentCriteria"); }} className="download-button" style={{ marginTop: "10px" }}>
                    <img src={DownloadIcon} alt="" width="30px" /> {buttonText.criteria}
                </Button>
            </div>
            <Col className="m-3 cstm-lh">
                <span className="txt-lbl">Asssessment ID</span><br />
                <span className="txt-data">{data.assessmentId}</span>
            </Col>
            <Col className="m-3 cstm-lh">
                <span className="txt-lbl">Unit Code</span><br />
                <span className="txt-data">{data.code}</span>
            </Col>
            <Col className="m-3 cstm-lh">
                <span className="txt-lbl">Unit Title</span><br />
                <span className="txt-data">{data.title}</span>
            </Col>
            <Col className="m-3 cstm-lh">
                <span className="txt-lbl">Released Date</span><br />
                <span className="txt-data">{data.date}</span>
            </Col>
            <Col className="m-3 cstm-lh">
                <span className="txt-lbl">Description</span><br />
                <span className="txt-data">{data.description}</span>
            </Col>
            <Col className="m-3 cstm-lh">
                <span className="txt-lbl">Last Moderation</span><br />
                <span className="txt-data">{data.lastModerationDate}</span>
            </Col>
            <Col className="m-3 cstm-lh col-md-4">
                <span className="txt-lbl">Assignement Brief</span><br />
                <Button onClick={() => { handleDownload(data.assessmentId, "AssignmentBrief"); }} className="download-button">
                    <img src={DownloadIcon} alt="" width="30px" /> {buttonText.assignment}
                </Button>
            </Col>
            <Col className="m-3 cstm-lh col-md-4">
                <span className="txt-lbl">Assignment Criteria</span><br />
                <Button onClick={() => { handleDownload(data.assessmentId, "AssignmentCriteria"); }} className="download-button" style={{ marginTop: "10px" }}>
                    <img src={DownloadIcon} alt="" width="30px" /> {buttonText.criteria}
                </Button>
            </Col>
            <Col className="m-3 cstm-lh col-md-6">
                <span className="txt-lbl">Assigned Marker</span><br />
                <MarkerCard markers={data.markers}></MarkerCard>
            </Col>
        </>
    )
}
export default ViewAssignment;