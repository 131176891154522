import { User } from '@/app/modules/types/types';
import { FETCH_LEVELS, FETCH_USER_BY_USER_ID, UPDATE_USER_SUPERVISOR } from '@/app/redux/actionTypes';
import { RootState } from '@/app/redux/store';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import UserDetailField from './UserDetailField';
import { formatDateToMonthDateYear } from '@/app/modules/helpers/FormikHelper';
import IMAGES from '@/app/utils/Images';
import Button from '../Button';
import COLORS from '@/app/utils/Colors';
import LearnerAssignmentsModal from '../LearnerAssignmentsModal';

type Props = {
    visible: boolean;
    handleClose: () => void;
    selectedUser: User | any;
    editable?: boolean,
    fetchData?: () => void;
};

type TextProps = {
    value?: string | number | null;
    heading?: string;
    headingStyle?: React.CSSProperties;
    valueStyle?: React.CSSProperties;
}




const UserDetailsPopUp: React.FC<Props> = ({ visible, handleClose, selectedUser, editable = true, fetchData }) => {

    const dispatch = useDispatch()

    const [show, setShow] = useState<boolean>(false);

    const [isEditing, setIsEditing] = useState(false)

    const [image, setImage] = useState('');

    const [imageFile, setImageFile] = useState<File | null>(null);

    const { user } = useSelector((state: RootState) => state.user)
    console.log("user: ",user)
    const [showAssignments, setShowAssignments] = useState(false);

    const [newLevel, setNewLevel] = useState<any[]>([])
    const [newCohort, setNewCohort] = useState<any[]>([]);

    const [userDetails, setUserDetails] = useState<{ [key: string]: string | string[] }>({})

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const { levels: apiLevels, cohorts } = useSelector((state: RootState) => state.cohort);

    useEffect(() => {
        setShow(visible);
        dispatch({ type: FETCH_LEVELS });
        dispatch({ type: FETCH_USER_BY_USER_ID, payload: { userId: selectedUser.id } })
    }, [dispatch, selectedUser.id, visible]);

    const handleDetailChange = useCallback((name: string, value: string | string[]) => {
        setUserDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    }, []);

    const handleEditImageClick = () => {
        fileInputRef.current?.click();
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setImageFile(file)
            const reader = new FileReader();
            reader.onloadend = () => setImage(reader.result as string);
            reader.readAsDataURL(file);
        }
    };

    console.log({ selectedUser, user })

    const Text: React.FC<TextProps> = ({ value, heading, headingStyle, valueStyle }) => {
        return (
            <div >
                {heading && (<span style={{ fontSize: '14px', fontWeight: 'bold', ...headingStyle }}>  {heading}  </span>)}
                {value && (<span style={{ fontSize: '14px', fontWeight: "normal", ...valueStyle }}>   &nbsp;:&nbsp;{value}   </span>)}
            </div>
        );
    };


    return (
        <>
            <Modal contentClassName='my-modal' backdrop="static" show={show} onHide={handleClose}>
                <Modal.Header className='px-4 py-3 fw-bold' closeButton >
                    <Modal.Title>User profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-row p-2'>

                        <div className='d-flex flex-column align-items-center' style={{ flex: 0.2 }}>
                            <img src={image ? image : user?.avatarChunkData ? `data:image/png;base64,${user?.avatarChunkData}` : IMAGES.AVTAR} alt='profile-img' style={imageStyle} />
                            <input
                                onError={(e) => {
                                    (e.target as HTMLImageElement).src = IMAGES.AVTAR; // Set default avatar on error
                                }}
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                            {isEditing && <div className=''>
                                <Button
                                    onClick={handleEditImageClick}
                                    label={'Upload'}
                                    backgroundColor={COLORS.WHITE}
                                    style={{ height: '40px' }}
                                    labelStyle={{ color: COLORS.PRIMARY, fontSize: '14px' }}
                                />
                            </div>}
                        </div>
                        <div style={{ flex: 0.5 }}>
                            {isEditing ? <UserDetailField
                                heading="Name"
                                editable={isEditing}
                                value={user?.name ? user?.name : ''}
                                name="name"
                                onChange={handleDetailChange}
                            /> :
                                <Text heading={user?.name} headingStyle={{ fontSize: 20 }} />
                            }
                            <UserDetailField
                                heading="Membership ID"
                                editable={isEditing}
                                value={user?.memberId ? user?.memberId : ''}
                                name="memberId"
                                onChange={handleDetailChange}
                            />
                            <UserDetailField
                                heading={user?.userType === 'learner' ? "Learner's Number" : "Educator's Number"}
                                editable={isEditing}
                                value={user?.phone ? user?.phone : ''}
                                name="phone"
                                onChange={handleDetailChange}
                            />
                            <UserDetailField
                                heading="Email"
                                editable={isEditing}
                                value={user?.email ? user?.email : ''}
                                name="email"
                                onChange={handleDetailChange}
                            />
                            <UserDetailField
                                heading="Enrolled Date"
                                value={user?.enrolmentDate ? formatDateToMonthDateYear(user?.enrolmentDate) : ''}
                                name="enrolmentDate"
                            />
                            <UserDetailField
                                heading="Level"
                                name="level"
                                value={isEditing ?
                                    user?.userType === 'learner' ? user?.levelsArray[0]?.id ? user?.levelsArray[0].id : '' : user?.levelsArray.map(item => item.id)
                                    : user?.levelsArray.map(item => item.name)
                                }
                                editable={isEditing}
                                inputType={user?.userType === 'learner' ? "select" : "multiselect"}
                                options={apiLevels}
                                onChange={(name, value) => {
                                    console.log(name, value)
                                    const formattedValue = Array.isArray(value) ? value : [value];
                                    console.log([value], formattedValue);
                                    if (user?.userType === 'learner') {
                                        setNewLevel(formattedValue);
                                    } else {
                                        setNewLevel(formattedValue);
                                    }
                                }}
                            />

                            <UserDetailField
                                heading="Centre Id"
                                value={user?.centreId}
                                name="centreId"
                            />
                            <UserDetailField
                                heading="Assigned Cohorts"
                                name="cohorts"
                                value={isEditing ?
                                    user?.userType === 'learner' ? user?.cohorts[0]?.id ? user?.cohorts[0].id : '' : user?.cohorts.map(item => item.id)
                                    : user?.cohorts.map(item => item.name)
                                }
                                editable={isEditing}
                                inputType={user?.userType === 'learner' ? "select" : "multiselect"}
                                options={cohorts || []}
                                onChange={(name, value) => {
                                    console.log(name, value)
                                    const formattedValue = Array.isArray(value) ? value : [value];
                                    console.log([value], formattedValue);
                                    if (user?.userType === 'learner') {
                                        setNewCohort(formattedValue);
                                    } else {
                                        setNewCohort(formattedValue);
                                    }
                                }}
                            />

                            {/* <UserDetailField
                                heading="Assigned Cohorts"
                                value={user?.cohorts.map(item => item.name).join(', ')}
                                name="cohorts"
                            /> */}
                            <div className='my-3 text-justify'>

                                {user?.userType === 'learner' ? <UserDetailField
                                    heading="Assigned Educators"
                                    value={user?.educators.map(item => item.name).join(', ')}
                                    name="educators"
                                /> : null}
                            </div>
                            {user?.userType === 'learner' ? <>
                                <Text
                                    heading={'Assignment'}
                                />
                                <div style={{ width: '316px' }} className='rounded p-2 border mt-2'>
                                    <Text
                                        heading="Assignment Submitted"
                                        valueStyle={{ fontWeight: 'bold' }}
                                        value={user && user.assignments ? user.assignments.length.toString() : '0'}
                                    />
                                    <div className='d-flex'>
                                        <div style={{ flex: 0.5 }}>
                                            <Text valueStyle={assignmentLabel} headingStyle={assignmentLabel} heading="High Pass" value={user?.assignmentStats.labelCounts['high pass']} />
                                            <Text valueStyle={assignmentLabel} headingStyle={assignmentLabel} heading="Pass" value={user?.assignmentStats.labelCounts['pass']} />
                                        </div>
                                        <div style={{ flex: 0.5 }}>
                                            <Text valueStyle={assignmentLabel} headingStyle={assignmentLabel} heading="Low Pass" value={user?.assignmentStats.labelCounts['low pass']} />
                                            <Text valueStyle={assignmentLabel} headingStyle={assignmentLabel} heading="Fail" value={user?.assignmentStats.labelCounts['fail']} />
                                            <Text valueStyle={reSubmissionAssignmentLabel} headingStyle={reSubmissionAssignmentLabel} heading="Resubmission" value={user?.assignmentStats.resubmissionCount} />
                                        </div>
                                    </div>
                                    <div className='text-center mt-2'>
                                        <Button
                                            backgroundColor={COLORS.WHITE}
                                            style={buttonStyle}
                                            labelStyle={labelStyle}
                                            label={'View Assignments'} onClick={() => setShowAssignments(true)} />
                                    </div>
                                </div>
                            </> : null}
                        </div>
                        {user?.userType === 'learner' ? <div style={{ flex: 0.3 }}>
                            <div className='bg-light p-3 pb-0 rounded'>
                                <h6 className='fw-bold'>Average Grade</h6>
                                <div className='d-flex flex-row align-items-end justify-content-between'>
                                    <h2 style={gradeText}>3.5</h2>
                                    <h6 style={unitsText}><span style={myUnits}>74&nbsp;</span>/&nbsp;100 Units</h6>
                                </div>
                            </div>
                        </div> : null}
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0 my-3'>
                    {isEditing && <Button
                        label={'Cancel'}
                        backgroundColor={COLORS.WHITE}
                        onClick={() => setIsEditing(!isEditing)}
                        style={{ height: '40px' }}
                        labelStyle={{ color: COLORS.PRIMARY, fontSize: '14px' }}
                    />}
                    {editable && <Button
                        label={isEditing ? 'Save Details' : 'Edit Details'}
                        backgroundColor={COLORS.PRIMARY}
                        onClick={async () => {
                            if (isEditing) {
                                await dispatch({
                                    type: UPDATE_USER_SUPERVISOR, payload: {
                                        updateUserId: user?.id,
                                        phone: userDetails?.phone,
                                        image: imageFile,
                                        name: userDetails?.name,
                                        level: newLevel.length > 0 && newLevel,
                                        cohorts: newCohort.length > 0 && newCohort,
                                        email: userDetails?.email,
                                        memberId: userDetails?.memberId
                                    }
                                });
                                if (fetchData) {
                                    await fetchData();
                                }
                                handleClose();
                            }
                            else {
                                setIsEditing(!isEditing)
                            }
                        }}
                        style={{ height: '40px' }}
                        labelStyle={{ color: COLORS.WHITE, fontSize: '14px' }}
                    />}
                </Modal.Footer>
            </Modal>
            {user?.assignments && <LearnerAssignmentsModal stats={user?.assignmentStats} assignments={user?.assignments} visible={showAssignments} handleClose={() => setShowAssignments(false)} />}
        </>
    )
}



const imageStyle: React.CSSProperties = {
    height: 100,
    width: 100,
    borderRadius: 50
};


const gradeText: React.CSSProperties = {
    color: '#2188E7',
    fontWeight: 'bold'
};

const myUnits: React.CSSProperties = {
    color: '#00C853',
    fontWeight: 'bold'
};

const unitsText: React.CSSProperties = {
    fontWeight: 'bold'
};


const assignmentLabel: React.CSSProperties = {
    color: '#8A8A8A',
    fontWeight: 'bold',
};

const reSubmissionAssignmentLabel: React.CSSProperties = {
    color: '#DC2626',
    fontWeight: 'bold',
};

const buttonStyle: React.CSSProperties = {
    border: `1px solid ${COLORS.PRIMARY}`,
    height: '40px',
};

const labelStyle: React.CSSProperties = {
    color: COLORS.PRIMARY
};


export default UserDetailsPopUp