//learner.tsx
import { LearnerAssignmentsGrading } from "@/app/modules/types/types";
import React, { useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DOWNLOAD_ASSIGNMENTS, FETCH_LEARNER_GRADING_ASSIGNMENT } from "@/app/redux/actionTypes";
import IMAGES from "@/app/utils/Images";
import Button from "@/app/components/Button";
import FilterDropdown from "@/app/components/Table/TableFilter";
import PaginatedTable from "@/app/components/Table/PaginatedTable";
import Loader from "@/app/components/Loading";
import { Dropdown } from "react-bootstrap";
import RatingModal from "./RatingModal";
import { useSelector } from "react-redux";
import { RootState } from "@/app/redux/store";
import { gradingFeedbackOptions, sortOptions } from "@/app/utils/helper";
import moment from "moment";

interface LearnerProps {
  learnerAssignmentsGrading: {
    data: LearnerAssignmentsGrading[];
    pageSize: number;
    currentPage: number;
    totalPages: number;
    totalRecords: number;
  } | null;
}
const Learner: React.FC<LearnerProps> = ({ learnerAssignmentsGrading }) => {
  const [open,setOpen] = useState<boolean>(false);
  const [assignmentID,setAssignmentID] = useState<string|null>(null)
  const {downloadAssignments, fileId} = useSelector((state: RootState) => state.gradingFeedback);

  // Sorting function
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [filter, setFilter] = useState<any>("");
  const [sort, setSort] = useState<any>("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isFilterStatus, setIsFilterStatus] = useState(false);
  const [isSortStatus, setIsSortStatus] = useState(false);
  const dispatch = useDispatch();

  const columns = [
    { header: "Assignment ID", accessorKey: "assessmentId" },
    { header: "Unit Title", accessorKey: "unitTitle" }, // corrected from "Unit_title"
    { 
      header: "Submitted Date", 
      // If you have no `uploadTimestamp`, use `createdAt`:
      accessorKey: "createdAt",
      cell: ({ row }) => moment(row.original.createdAt).format("MMMM Do YYYY, h:mm A")
    },
    {
      header: "Results",
      // If 'results' doesn't exist, show 'status' or default to "Under Review"
      cell: ({ row }) => {
        const status = row.original.status || "Under Review";
        // Apply relevant className based on status:
        let className = "label-resubmission";
        if (status === "Fail") className = "label-danger";
        else if (["High Pass", "Pass"].includes(status)) className = "label-primary";
        
        return (
          <div className={`d-flex align-items-center gap-2 ${className}`}>
            <span className="border rounded-circle p-1" />
            <label className="item">{status}</label>
          </div>
        );
      },
    },
    {
      header: "Actions",
      cell: ({ row }) => (
        <Dropdown
          id={row.original.assessmentId}
          show={openDropdownId === row.original.assessmentId}
          onToggle={() => handleToggle(row.original.assessmentId)}
        >
          <Dropdown.Toggle
            as="img"
            src={IMAGES.ACTION}
            alt="Menu Icon"
            style={{ cursor: "pointer" }}
            onClick={() => handleToggle(row.original.assessmentId)}
          />
          <Dropdown.Menu className="assignment-actions-dropdown z-4">
            <Dropdown.Item
              onClick={() => {
                dispatch({ type: DOWNLOAD_ASSIGNMENTS, payload: row.original.file_id});
              }}
            >
              Download feedback
            </Dropdown.Item>
            <Dropdown.Item onClick={() => {
                setAssignmentID(row.original.assessmentId) // or whatever ID you have
                setOpen(true);
            }}>Rate feedback</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];  

  const handleToggle = (id: any) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };

  const fetchData = (page, limit, search = "", filter = "", sort = "") => {
    dispatch({
      type: FETCH_LEARNER_GRADING_ASSIGNMENT,
      payload: { page, limit, search, filter, sort },
    });
  };

  const handleSearchChange = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value);
    },
    500
  );

  const handleStatusFilter = (status: (string | number) []) => {
    setFilter(status);
  };

  useEffect(() => {
    fetchData(1, 10, searchQuery, filter, sort);
  }, [searchQuery, filter, sort]);

  useEffect(() => {
    fetchData(1, 10, searchQuery, filter, sort);
  }, []);

  useEffect(() => {
    if (downloadAssignments) {
      const blob = new Blob([downloadAssignments], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `assignment_${fileId}_marked.docx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
  }, [downloadAssignments, fileId]);

  const handleSortData = (status: string | number) => {
    setSort(status);
  };

  return (
    <>
      {!learnerAssignmentsGrading && <Loader />}
      {learnerAssignmentsGrading?.data && learnerAssignmentsGrading?.data?.length > 0 ? (
        <PaginatedTable<LearnerAssignmentsGrading>
          columns={columns}
          data={learnerAssignmentsGrading?.data}
          pageCount={learnerAssignmentsGrading?.totalPages || 10}
          fetchData={fetchData}
          handleSearchChange={handleSearchChange}
          customButtons={
            <div className="d-flex gap-2">
               <FilterDropdown
                type="sort"
                options={sortOptions}
                onSortSelect={handleSortData}
                placeholder={
                  <Button
                    label={""}
                    className={"assignment-btn"}
                    onClick={() => {
                      setIsSortStatus(!isSortStatus);
                    }}
                    isIcon={true}
                    icon={IMAGES.SORT_ICON}
                  />
                }
              />
              <FilterDropdown
                type="filter"
                options={gradingFeedbackOptions}
                onFilterSelect={handleStatusFilter}
                placeholder={
                  <Button
                    label={"Filter"}
                    className={"assignment-btn"}
                    onClick={() => {
                      setIsFilterStatus(!isFilterStatus);
                    }}
                    isIcon={true}
                    icon={IMAGES.FILTER}
                  />
                }
              />
            </div>
          }
        />
      ) : (
        <label className="d-flex justify-content-center">
          No records fonud!
        </label>
      )}
      <RatingModal open={open} setOpen={setOpen} assignmentID={assignmentID}/>
    </>
  );
};

export default Learner;
