import React, { useEffect, useState } from "react";

interface LearnerListProps {
  learners: {
    id: string;
    name: string;
    memberId: string;
  }[];
  selectedLearners?: string[];
  onSelectedChange: (selectedIds: string[]) => void;
  onclickViewDetails?: (id: string) => void;
}

const LearnerList: React.FC<LearnerListProps> = ({
  learners,
  selectedLearners = [],
  onSelectedChange,
  onclickViewDetails,
}) => {
  const [selected, setSelected] = useState<string[]>(selectedLearners);

  useEffect(() => {
    // Sync local state with parent updates
    setSelected(selectedLearners);
  }, [selectedLearners]);

  const handleCheckboxChange = (id: string, isChecked: boolean) => {
    let updatedSelected = [...selected];

    if (isChecked) {
      // Add without duplicates
      if (!updatedSelected.includes(id)) {
        updatedSelected.push(id);
      }
    } else {
      // Remove if present
      updatedSelected = updatedSelected.filter((selectedId) => selectedId !== id);
    }

    setSelected(updatedSelected);
    onSelectedChange(updatedSelected);
  };

  return (
    <div className="table-container z-2 mt-4">
      <table className="learner-table">
        <thead className="sticky-top z-1 bg-white">
          <tr>
            <th scope="col" className="user-heading" />
            <th scope="col" className="user-heading">
              Name
            </th>
            <th scope="col" className="user-heading">
              Membership ID
            </th>
            <th scope="col" className="user-heading">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="z-0">
          {learners.map((item, index) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  checked={selected.includes(item.id)}
                  onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
                />
              </td>
              <td>{item.name}</td>
              <td>{item.memberId}</td>
              <td
                onClick={() => onclickViewDetails?.(item.id)}
                className="view-detail fw-medium"
              >
                View Details
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LearnerList;