//educator.tsx
import Loader from "@/app/components/Loading";
import PaginatedTable from "@/app/components/Table/PaginatedTable";
import FilterDropdown from "@/app/components/Table/TableFilter";
import { AssignmentGrading } from "@/app/modules/types/types";
import { FETCH_GRADING_ASSIGNMENT } from "@/app/redux/actionTypes";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import debounce from "lodash.debounce";
import Button from "@/app/components/Button";
import IMAGES from "@/app/utils/Images";
import { useNavigate } from "react-router-dom";
import { sortOptions } from "@/app/utils/helper";

interface EducatorProps {
  assignmentsGrading: {
    data: AssignmentGrading[];
    page: number;
    total: number;
    totalPages: number;
  } | null;
}
const Educator: React.FC<EducatorProps> = ({ assignmentsGrading }) => {
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [filter, setFilter] = useState<any>("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isFilterStatus, setIsFilterStatus] = useState(false);
  const [sort, setSort] = useState<any>("");
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isSortStatus, setIsSortStatus] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  interface FilterOption {
    label: string;
    value: string | number;
  }
  interface MultipleFilterOption {
    [key: string]: FilterOption[];
  }

  const headings = ["Level", "Grading Status"];
  const activeOptions: MultipleFilterOption[] = [
    {
      Level: [
        { label: "Level 3", value: "Level 3" },
        { label: "Level 5", value: "Level 5" },
        { label: "Level 7", value: "Level 7" },
      ],
    },
    {
      "Grading Status": [
        { label: "Ready to Review", value: "ready_to_review" },
        { label: "In Progress", value: "first_marking_in_progress" },
        { label: "In Progress/2nd marking", value: "seond_marking_in_progress" },
        { label: "Done", value: "done" },
      ],
    },
  ];

  const getStatus = (status :string) => {
    if(status === 'ready_to_review') return 'Ready to Review';
    else if(status === 'done') return 'Done';
    else if(status === 'first_marking_in_progress') return 'In Progress';
    else return 'In Progress/2nd marking'
  }
  const columns = [
    { header: "Name", accessorKey: "Learner_firstname" },
    { header: "Membership ID", accessorKey: "membership_id" },
    { header: "Enrolled Course", accessorKey: "level" },
    {
      header: "Status",
      cell: ({ row }) => (
        <div
          className={`d-flex align-items-center gap-2  ${
            row.original.Status ? row.original.Status === "ready_to_review"
              ? "label-success"
              : row.original.Status === "done"
              ? "label-primary"
              : "label-danger" : 'label-danger'
          }`}
        >
          <span className="border rounded-circle p-1" />
          <label className="item">{getStatus(row.original.Status)}</label>
        </div>
      ),
    },
    {
      header: "Actions",
      cell: ({ row }) => (
        <Dropdown
          id={row.original.id}
          show={openDropdownId === row.original.id}
          onToggle={() => handleToggle(row.original.id)}
        >
          <Dropdown.Toggle
            as="img"
            src={IMAGES.ACTION}
            alt="Menu Icon"
            style={{ cursor: "pointer" }}
            onClick={() => handleToggle(row.original.id)}
          />
          <Dropdown.Menu className="assignment-actions-dropdown z-4">
            <Dropdown.Item
              onClick={() => {
                navigate(`/grading-feedback/${row.original.level}`, {
                  state: { assignmentId: row.original.assignmentId,file_id:row.original.file_id ,levelStatus:row.original.status,item:row.original},
                });
              }}
            >
              Review AI marking
            </Dropdown.Item>
            <Dropdown.Item>Download</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];
  const handleToggle = (id: any) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };
  const fetchData = (page, limit, search = "", filter = "", sort = "") => {
    dispatch({
      type: FETCH_GRADING_ASSIGNMENT,
      payload: { page, limit, search, filter, sort },
    });
  };

  const handleSearchChange = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value);
    },
    500
  );
  const handleStatusFilter = (status: (string | number)[]) => {
    setFilter(status);
  };

  const handleSortData = (status: string | number) => {
    setSort(status);
  };

  useEffect(() => {
    fetchData(page, pageSize, searchQuery, filter, sort);
  }, [page, pageSize, searchQuery, filter, sort]);

  const handlePaginationChange = (pageNumber: number, pageSize: number) => {
    setPage(pageNumber);
    setPageSize(pageSize);
  };
  return (
    <>
      {!assignmentsGrading && <Loader />}
      <PaginatedTable<AssignmentGrading>
        columns={columns}
        data={assignmentsGrading?.data || []}
        pageCount={assignmentsGrading?.totalPages || 2}
        fetchData={fetchData}
        handlePaginationChange={handlePaginationChange}
        handleSearchChange={handleSearchChange}
        customButtons={
          <div className="d-flex gap-2">
            <FilterDropdown
              type="sort"
              options={sortOptions}
              onSortSelect={handleSortData}
              placeholder={
                <Button
                  label={""}
                  className={"assignment-btn"}
                  onClick={() => {
                    setIsSortStatus(!isSortStatus);
                  }}
                  isIcon={true}
                  icon={IMAGES.SORT_ICON}
                />
              }
            />
            <FilterDropdown
              type="filter"
              options={activeOptions}
              headings={headings}
              onFilterSelect={handleStatusFilter}
              isMultiple={true}
              placeholder={
                <Button
                  label={"Filter"}
                  className={"assignment-btn"}
                  onClick={() => {
                    setIsFilterStatus(!isFilterStatus);
                  }}
                  isIcon={true}
                  icon={IMAGES.FILTER}
                />
              }
            />
          </div>
        }
      />
    </>
  );
};

export default Educator;
