//GradingFeedback.tsx
import Layout from "@components/Layout";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/app/redux/store";
import { toast } from "react-toastify";
import Educator from "./Educator";
import Learner from "./Learner";
import ApiRequest from "@/app/api/ApiRequest";
import "./gradingfeedback.module.style.css";

interface GradingFeedbackProps {}

const GradingFeedback: React.FC<GradingFeedbackProps> = ({}) => {
  // Move useSelector inside the component body
  const { user, userType } = useSelector((state: RootState) => state.auth);


  // Default structures for state
  const defaultEducatorGrading = {
    data: [],
    page: 1,
    total: 0,
    totalPages: 0,
  };

  const defaultLearnerGrading = {
    data: [],
    pageSize: 10,
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
  };

  const [assignmentsGrading, setAssignmentsGrading] = useState(defaultEducatorGrading);
  const [learnerAssignmentsGrading, setLearnerAssignmentsGrading] = useState(defaultLearnerGrading);
  const [error, setError] = useState<string | null>(null);

  // Fetch assignments
  useEffect(() => {
    const fetchAssignments = async () => {
      try {
        const params: any = {};
        if (userType === "educator") {
          params.markerId = user.id // Replace dynamically if required
        } else if (userType === "learner") {
          params.learnerId = user.id // Replace dynamically if required
        }
        console.log("params: ", params)
        const response = await ApiRequest.get(`${process.env.REACT_APP_EDUMARK_SYSTEMS_URL}/grading-feedback`, { params });
        console.log("response: ", response)
        if (userType === "educator") {
          setAssignmentsGrading(response.data.data || defaultEducatorGrading);
        } else if (userType === "learner") {
          setLearnerAssignmentsGrading(response.data.data || defaultLearnerGrading);
        }
      } catch (err: any) {
        setError(err.response?.data?.error || "Failed to fetch assignments");
      }
    };

    fetchAssignments();
  }, [userType]);

  // Display error message if any
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Layout>
      <div className="grading-feedback-container m-4 vh-100">
        <div className="sticky-top z-3 bg-white">
          <div className="heading-container">
            <span className="heading">Grading & Feedback</span>
            {userType === "supervisor" && (
              <span className="label">
                Manage course assessment and feedback
              </span>
            )}
          </div>
        </div>
        <div>
          <div className="z-2 my-3">
            {userType === "educator" && (
              <Educator assignmentsGrading={assignmentsGrading} />
            )}
            {userType === "learner" && (
              <Learner learnerAssignmentsGrading={learnerAssignmentsGrading} />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GradingFeedback;
