import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_SUCCESS_COHORT, DELETE_COHORTS, DELETE_LEARNER_FROM_COHORT, FETCH_COHORT_LEARNERS, FETCH_COHORTS, MOVE_LEARNER } from '@/app/redux/actionTypes';
import { RootState } from '@/app/redux/store';
import PaginatedTable from '@components/Table/PaginatedTable';
import Loader from '@/app/components/Loading';
import LearnerList from '@components/CreateAssignment/LearnerList';
import { RiDeleteBinLine } from 'react-icons/ri';
import styles from './Users.module.css';
import COLORS from '@/app/utils/Colors';
import debounce from 'lodash.debounce';
import { HiLogout } from "react-icons/hi";
import { toast } from 'react-toastify';
import { confirmationModal } from '@/app/components/ConfirmationModal';
import { successPopup } from '@/app/components/SuccessModal';
import MoveCohortList from '@/app/components/MoveCohortList';
import TableActionDropdown from '@/app/components/Table/TableAction';
import UserDetailsPopUp from '@/app/components/UserDetailsPopUp/UserDetailsPopUp';
// import ActionDropdown from '@/app/components/ActionDropdown';
interface CohortTableProps {
    selectedSort: string
    selectedFilterUserStatus: string[]
    selectedFilterLevels: string[];
}

const CohortTable: React.FC<CohortTableProps> = ({ selectedSort, selectedFilterUserStatus, selectedFilterLevels }) => {
    const [pages, setPages] = useState<number>(1);
    const [searchQuery, setSearchQuery] = useState("");

    const [pageSize, setPageSize] = useState<number>(10);

    const [selectedCohortId, setSelectedCohort] = useState<string>('')

    const [selectedLearners, setSelectedLearners] = useState<string[]>([])

    const [moveLearners, setMoveLearners] = useState(false)

    const dispatch = useDispatch();

    const [userProfile, setUserProfile] = useState(false)

    const [learnerDetails, setLearnerDetails] = useState('')

    const { cohorts, success, page, totalPages, loading, selectedCohort, mappedLearners, error } = useSelector((state: RootState) => state.cohort);

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);


    const fetchCohorts = (
        page: number,
        rows: number,
        sortBy: string = '',
        query: string = '',
        filterUserStatus: string[] = [],
        filterLevels: string[] = []
    ) => {
        const formattedFilterUserStatus = Array.isArray(filterUserStatus) ? JSON.stringify(filterUserStatus) : JSON.stringify([]);
        const formattedFilterLevels = Array.isArray(filterLevels) ? JSON.stringify(filterLevels) : JSON.stringify([]);

        dispatch({
            type: FETCH_COHORTS, payload: {
                page,
                limit: rows,
                search: query,
                sortBy,
            }
        });
    };


    const handleSearchChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    }, 500);

    const handlePaginationChange = (pageNumber: number, pageSize: number) => {
        setPages(pageNumber);
        setPageSize(pageSize);
    };

    const handleSuccess = async (message: string) => {
        // Pass success message here
        await successPopup(message);
        dispatch({ type: CLEAR_SUCCESS_COHORT })
        setMoveLearners(false)
        setSelectedCohort('')
        fetchCohorts(1, 10, '')

    };

    const handleViewCohort = (cohort: any) => {
        setSelectedCohort(cohort.id)
        setSelectedCohort(cohort)
        dispatch({
            type: FETCH_COHORT_LEARNERS,
            payload: { cohortId: cohort?.id, userType: 'learner' }
        })
    }

    useEffect(() => {
        fetchCohorts(pages, pageSize, selectedSort, searchQuery, selectedFilterUserStatus, selectedFilterLevels);
    }, [pages, pageSize, searchQuery, selectedSort, selectedFilterUserStatus, selectedFilterLevels]);


    useEffect(() => {
        if (success) {
            handleSuccess(success)
        }
    }, [success])


    const columns = [
        { header: 'Cohort Name', accessorKey: 'name' },
        { header: 'Learners', accessorKey: 'learners' },
        { header: 'Course', accessorKey: 'course.name' },
        {
            header: 'Actions', cell: ({ row }) => (
                <TableActionDropdown actions={[
                    {
                        label: 'View Cohort', onClick: () => {
                            handleViewCohort(row.original)
                        }
                    },
                    {
                        label: "Delete Cohort",
                        onClick: async () => {
                          const cohortId = row.original.id;
                          const result = await confirmationModal();
                          if (result) {
                            // Assuming DELETE_COHORTS is an async action
                            await dispatch({ type: DELETE_COHORTS, payload: { cohortId } });
                            // Now reload the entire page
                            window.location.reload();
                          }
                        },
                      }                      
                ]} />
            )
        },
    ];

    const handleDelete = async () => {
        const result = await confirmationModal();
        if (result) {
            dispatch({ type: DELETE_LEARNER_FROM_COHORT, payload: { cohortId: selectedCohortId, learnerIds: selectedLearners } })
        }
    }

    const EditCohortSection = () => {
        return (
            <>
                <div className={styles.flexContainer}>
                    <div onClick={() => setMoveLearners(true)} className={styles.iconContainer}>
                        <HiLogout color={COLORS.PRIMARY} size={24} />
                        <span style={{ color: COLORS.PRIMARY }}>Move to</span>
                    </div>
                    <div onClick={handleDelete} className={styles.deleteIcon}>
                        <RiDeleteBinLine color={COLORS.PRIMARY} size={24} />
                    </div>
                </div>
                <div className="cohort-details d-flex justify-content-between align-items-center px-lg-3 px-0">
                    <div className="card-title d-flex align-items-center gap-4">
                        <div>
                            <label className="title">{selectedCohort?.name}</label>
                        </div>
                    </div>
                    <div className="card-title d-flex align-items-center gap-4">
                        <div>
                            <label className="d-block card-heading">
                                {"Total no. of Learners"}
                            </label>
                            <label className="title">{selectedCohort?.learnerIds?.length}</label>
                        </div>
                    </div>
                </div>

                <div className="mt-4 d-flex gap-3 justify-content-lg-start justify-content-between align-items-center px-lg-3 px-0">
                    <label className="course-heading">{'Course Name'}</label>
                    <label className="course-name">{selectedCohort?.level?.name}</label>
                </div>
            </>
        )
    }

    return (
        <>
          {loading ? (
            <Loader />
          ) : (
            <div className="row">
              <div className="col-md-8">
                {cohorts && cohorts.length > 0 ? (
                  <PaginatedTable
                    columns={columns}
                    data={cohorts}
                    pageCount={totalPages}
                    fetchData={fetchCohorts}
                    handleSearchChange={handleSearchChange}
                    handlePaginationChange={handlePaginationChange}
                  />
                ) : (
                  <div>No cohorts found.</div>
                )}
              </div>
      
              {selectedCohortId && (
                <div className={`col-md-4 ${selectedCohortId && "border-start"}`}>
                  {mappedLearners && (
                    <>
                      <MoveCohortList
                        visible={moveLearners}
                        handleClose={() => setMoveLearners(false)}
                        handleSubmit={(newCohortId) =>
                          dispatch({
                            type: MOVE_LEARNER,
                            payload: {
                              newCohortId,
                              oldCohortId: selectedCohortId,
                              learnerIds: selectedLearners,
                            },
                          })
                        }
                      />
                      <EditCohortSection />
                      <LearnerList
                        onclickViewDetails={(id) => {
                          setLearnerDetails(id);
                          setUserProfile(true);
                        }}
                        onSelectedChange={(learners) => setSelectedLearners(learners)}
                        learners={mappedLearners}
                      />
                    </>
                  )}
                </div>
              )}
      
              {userProfile && (
                <UserDetailsPopUp
                  editable={false}
                  visible={userProfile}
                  handleClose={() => setUserProfile(false)}
                  selectedUser={{ id: learnerDetails }}
                />
              )}
            </div>
          )}
        </>
      );
    };

export default CohortTable;
