import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_USERS, DELETE_USER } from '@/app/redux/actionTypes';  // <-- Adjust if needed
import { RootState } from '@/app/redux/store';
import PaginatedTable from '@components/Table/PaginatedTable';
import Loader from '@/app/components/Loading';
import { debounce } from 'lodash';
import { User } from '@/app/modules/types/types';
import { formatDateToMonthDateYear } from '@/app/modules/helpers/FormikHelper';
import { toast } from 'react-toastify';
import TableActionDropdown from '@/app/components/Table/TableAction';
import { confirmationModal } from '@/app/components/ConfirmationModal'; // <-- If you have a reusable modal

interface SupervisorTableProps {
  selectedSort: string;
}

const SupervisorTable: React.FC<SupervisorTableProps> = ({ selectedSort }) => {
  const dispatch = useDispatch();
  const { users, pagination, loading, error } = useSelector(
    (state: RootState) => state.user
  );

  // You could store a selected user if you want a "View" or "Edit" modal
  // const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const fetchData = async (
    page: number,
    rows: number,
    sortBy: string = "",
    query: string = ""
  ) => {
    dispatch({
      type: FETCH_USERS,
      payload: {
        page,
        limit: rows,
        search: query,
        userType: "supervisor",
        sortBy,
      },
    });
  };

  useEffect(() => {
    fetchData(1, 10);
  }, []);

  useEffect(() => {
    // Re-fetch data if the sort changes
    fetchData(1, 10, selectedSort);
  }, [selectedSort]);

  // Example: handle the Delete action with a confirmation prompt.
  const handleDeleteSupervisor = async (supervisor: User) => {
    const confirmed = await confirmationModal();
    if (confirmed) {
      dispatch({ type: DELETE_USER, payload: { userId: supervisor.id } });
      // If you want a full page reload, do:
      window.location.reload();
      // Otherwise, you could also re-fetch data:
      // fetchData(1, 10, selectedSort);
    }
  };

  // Example: handle the View action — open a modal, route to a page, etc.
  const handleViewSupervisor = (supervisor: User) => {
    // e.g., open a user profile popup or navigate to a detail page
    // setSelectedUser(supervisor);
    console.log("View Supervisor", supervisor);
  };

  const columns = [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Joining Date",
      cell: ({ row }: { row: { original: User } }) => (
        <span>{formatDateToMonthDateYear(row.original.enrolmentDate)}</span>
      ),
    },
    {
      header: "Actions",
      cell: ({ row }: { row: { original: User } }) => (
        <TableActionDropdown
          actions={[
            {
              label: "View Supervisor",
              onClick: () => handleViewSupervisor(row.original),
            },
            {
              label: "Delete Supervisor",
              onClick: () => handleDeleteSupervisor(row.original),
            },
          ]}
        />
      ),
    },
  ];

  const handleSearchChange = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // If you also want to sort by `selectedSort`, pass it here.
      fetchData(1, 10, selectedSort, event.target.value);
    },
    500
  );

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <>
      {loading && <Loader />}
      {users && pagination && (
        <PaginatedTable<User>
          columns={columns}
          data={users}
          pageCount={pagination.totalPages}
          fetchData={fetchData}
          handleSearchChange={handleSearchChange}
        />
      )}

      {/* If you have a user details modal: 
      {selectedUser && (
        <UserDetailsModal
          user={selectedUser}
          onClose={() => setSelectedUser(null)}
        />
      )} */}
    </>
  );
};

export default SupervisorTable;
