import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import axios from "axios";

interface AssignmentsProps {
  labelText: string;
}

// Register required components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler);

const AssignmentsDetailNew: React.FC<AssignmentsProps> = ({ labelText }) => {
  const [chartData, setChartData] = useState<any>({
    labels: [],
    datasets: [],
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const containerStyles: React.CSSProperties = {
    height: "290px",
  };

  const heading: React.CSSProperties = {
    fontSize: "13.93px",
    fontWeight: "700",
    lineHeight: "16.86px",
    color: "#000000",
  };

  const chartContainerStyle: React.CSSProperties = {
    border: "1px solid transparent",
    borderColor: "#E5E7E7",
    borderRadius: "8.36px",
    margin: "0px",
    height: "200px",
    width: "100%",
  };

  useEffect(() => {
    const fetchGradesDistribution = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_ANALYTICS_URL}/grades-distribution-dashboard`
          // {
          //   cohort: "cohort-123",
          //   learner: "some-learner",
          //   educator: "John Doe",
          // }
        );               

        const apiData = response.data;

        const datasets = apiData.datasets.map((dataset: any) => ({
          label: dataset.label,
          data: dataset.data,
          backgroundColor: {
            Fail: "#EE9797",
            "Low Pass": "#FFB08F",
            Pass: "#FFEDB0",
            "High Pass": "#99E3B1",
          }[dataset.label],
          borderRadius: {
            topLeft: dataset.label === "Fail" ? 0 : 10,
            topRight: dataset.label === "Fail" ? 0 : 10,
            bottomLeft: dataset.label === "Fail" ? 10 : 0,
            bottomRight: dataset.label === "Fail" ? 10 : 0,
          },
          borderSkipped: false,
          categoryPercentage: 0.6,
          barThickness: 30,
        }));

        setChartData({
          labels: apiData.labels,
          datasets,
        });
      } catch (err) {
        console.error("Error fetching grades distribution data:", err);
        setError("No Data To Show");
      } finally {
        setLoading(false);
      }
    };

    fetchGradesDistribution();
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          boxWidth: 12,
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        position: "nearest" as const,
        yAlign: "bottom" as const,
        callbacks: {
          label: function (tooltipItem: any) {
            const label = tooltipItem.dataset.label || "Label";
            const value = tooltipItem.raw;
            return `${label}: ${value}`;
          },
        },
        displayColors: false,
        backgroundColor: "#2E2E48",
        bodyFont: {
          size: 14,
        },
        padding: 8,
        titleColor: "#ffffff",
        bodyColor: "#ffffff",
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        padding: 10,
        offset: true,
      },
      y: {
        border: { dash: [8, 8] },
        stacked: true,
        grid: {
          display: true,
          color: "rgba(0, 0, 0, 0.1)",
          borderColor: "rgba(0, 0, 0, 0.5)",
          tickLength: 1,
        },
        min: 0,
        ticks: {
          stepSize: 20,
          padding: 10,
        },
        margin: 10,
      },
    },
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container card-container" style={containerStyles}>
      <label className="heading" style={heading}>
        {labelText}
      </label>
      <div className="chart-container" style={chartContainerStyle}>
        <Bar data={chartData} options={options} />
      </div>
    </div>
  );
};

export default AssignmentsDetailNew;
