import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { assUserFieldByUserType } from '@modules/static/FormArray';
import { FieldUpateProfile } from '@modules/types/types';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import Input from './Input';
import Button from '@components/Button';
import COLORS from '../utils/Colors';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../modules/helpers/assetHelper';
import DatePicker from './DatePicker';
import { useDispatch } from 'react-redux';
import { _USER_CLEAR_SUCCESS, CREATE_USER } from '../redux/actionTypes';
import LevelsDropdown from './LevelsDropdown';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import Asterisk from './Asterisk';
import { FormControlLabel, Switch } from '@mui/material';

type Props = {
    visible: boolean;
    handleClose: () => void;
    role: string
};

const AddUserRole: React.FC<Props> = ({ visible, handleClose, role }) => {

    const [show, setShow] = useState<boolean>(false);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const { levels, cohorts } = useSelector((state: RootState) => state.cohort);

    const dispatch = useDispatch()

    const { success } = useSelector((state: RootState) => state.user)

    useEffect(() => {
        setShow(visible);
        setWindowHeight(window.innerHeight);
    }, [visible]);

    // Assuming the `updateProfileFields` is fetched based on the user type
    const updateProfileFields: FieldUpateProfile[] = assUserFieldByUserType[role];


    const initialValues = updateProfileFields?.reduce<Record<string, any>>((values, field) => {
        values[field.name] = field.prefilled ? 'Prefilled Value' : (field.type === 'switch' ? false : '');
        return values;
    }, {});

    console.log('initialValues: ', initialValues)
    // Create a Yup validation schema dynamically
    const validationSchema = Yup.object().shape(
        updateProfileFields?.reduce<Record<string, any>>((schema, field) => {
            if (field.required) {
                schema[field.name] = field.type === 'date'
                    ? Yup.date().required(`${field.label} is required`)
                    : field.type === 'switch'
                        ? Yup.boolean().required(`${field.label} is required`)
                        : field.type === 'level' || field.type === 'cohort'
                            ? Yup.array().min(1, `${field.label} is required`).required(`${field.label} is required`)
                            : Yup.string().required(`${field.label} is required`);
            }
            return schema;
        }, {})
    );


    useEffect(() => {
        if (success) {
            Swal.fire({
                html: `<p style='font-size: 16px;'>${success}<br/></p>`,
                imageUrl: `${toAbsoluteUrl('/media/modal/confirm.png')}`,
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom image',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch({ type: _USER_CLEAR_SUCCESS })
                    handleClose();
                }
            });
        }
    }, [success])



    const labelStyle: React.CSSProperties = {
        fontSize: '14px',
        color: COLORS.BLACK,
        lineHeight: '20px',
    };

    const handleLearnerChange = useCallback((newSelectedUsers: any, setFieldValue: any, name: string) => {
        setFieldValue(name, newSelectedUsers);
    }, []);

    const handleCohortChange = useCallback((newSelectedUsers: any, setFieldValue: any, name: string) => {
        console.log({ newSelectedUsers })
        const selectedValue = Array.isArray(newSelectedUsers) ? newSelectedUsers : [newSelectedUsers];
        setFieldValue(name, selectedValue);
    }, []);

    return (
        <Modal size="lg" centered backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add new {role}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span className='ps-3 text-muted fs-6'>
                    Required fields are marked with an asterisk <Asterisk />
                </span>
                {/* Formik Form */}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        values['userType'] = role
                        console.log({ values });
                        // return
                        dispatch({ type: CREATE_USER, payload: { values } })
                    }}
                >
                    {({ handleSubmit, handleChange, errors, touched, setFieldValue }) => (
                        <>
                            <Form onSubmit={handleSubmit} className="pb-5 pt-3" style={{ maxHeight: windowHeight - 300, overflowY: 'auto', overflowX: 'hidden' }}>
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-lg-7">
                                        {updateProfileFields?.map((field, index) => (
                                            <div key={index} className={`${index > 0 ? 'mt-4' : ''} px-md-3`}>
                                                {field.type === 'text' && (
                                                <Field
                                                name={field.name}
                                                component={Input}
                                                label={field.label}
                                                placeholder={field.placeholder}
                                                onChange={handleChange} // This ensures that Formik's state is updated
                                                />                                            
                                                  )}
                                                {field.type === 'date' && (
                                                    <>
                                                        <h6 style={labelStyle} className="form-check-label mb-2">
                                                            {field.label} {field.required && <Asterisk />}
                                                        </h6>
                                                        <div>
                                                            <Field
                                                                mandatory={field.required}
                                                                name={field.name}
                                                                label={field.label}
                                                                component={DatePicker} // Use the custom DatePicker component
                                                                onChange={handleChange(field.name)}// Explicitly calling handleChange
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                {field.type === 'switch' && (
                                                    <>
                                                        <h6 style={labelStyle} className="form-check-label mb-2">
                                                            {field.label}
                                                        </h6>
                                                        <div className="form-check form-switch" >
                                                            <FormControlLabel
                                                                control={
                                                                    <Field
                                                                        component={Switch}
                                                                        name={field.name}
                                                                        id={field.name}
                                                                        onChange={handleChange(field.name)}
                                                                        style={{
                                                                            width: '48px',
                                                                            height: '40px',
                                                                            padding: '0',
                                                                            transition: 'transform 0.3s ease',
                                                                        }} />
                                                                }
                                                                label={""}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                {field.type === 'level' && (
                                                    <>
                                                        <h6 style={labelStyle} className="form-check-label mb-2">
                                                            {field.label} <Asterisk />
                                                        </h6>
                                                        <div>
                                                            <Field
                                                                name={field.name}
                                                                multiple={role === 'educator' ? true : false}
                                                                options={levels}
                                                                label={field.label}
                                                                component={LevelsDropdown} // Use the custom DatePicker component
                                                                onSelectedLevelsChange={(newSelectedUsers: any) => handleLearnerChange(newSelectedUsers, setFieldValue, field.name)}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                {field.type === 'cohort' && (
                                                    <>
                                                        <h6 style={labelStyle} className="form-check-label mb-2">
                                                            {field.label} <Asterisk />
                                                        </h6>
                                                        <div>
                                                            <Field
                                                                name={field.name}
                                                                multiple={role === 'educator'}
                                                                label={field.label}
                                                                component={LevelsDropdown}
                                                                options={cohorts}
                                                                onSelectedLevelsChange={(newSelectedUsers: any) => handleCohortChange(newSelectedUsers, setFieldValue, field.name)}
                                                            />
                                                        </div>
                                                    </>
                                                )}

                                                {errors[field.name] && touched[field.name] && (
                                                    <div className="text-danger">{errors[field.name]?.toString()}</div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </Form>
                            <Modal.Footer>
                                <div className="column">
                                    <Button
                                        label={'Cancel'}
                                        backgroundColor={COLORS.WHITE}
                                        onClick={handleClose}
                                        labelStyle={{ color: COLORS.PRIMARY, fontSize: '14px' }}
                                    />
                                    <Button
                                        type="submit"
                                        className="ms-2"
                                        label={'Create'}
                                        backgroundColor={COLORS.PRIMARY}
                                        style={{ height: 40 }}
                                        labelStyle={{ color: COLORS.WHITE, fontSize: '14px' }}
                                        onClick={handleSubmit}
                                    />
                                </div>
                            </Modal.Footer>
                        </>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default AddUserRole;
