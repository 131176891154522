import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _USER_CLEAR_SUCCESS, ACT_DEACTIVATE_USER, DELETE_USER, FETCH_COHORTS, FETCH_USERS, RESEND_CREDENTIALS } from '@/app/redux/actionTypes';
import { RootState } from '@/app/redux/store';
import { debounce } from 'lodash';
import PaginatedTable from '@components/Table/PaginatedTable';
import Loader from '@/app/components/Loading';
import { User } from '@/app/modules/types/types';
import { formatDateToMonthDateYear } from '@/app/modules/helpers/FormikHelper';
import { toast } from 'react-toastify';
import TableActionDropdown from '@/app/components/Table/TableAction';
import { successPopup } from '@/app/components/SuccessModal';
import { confirmationModal } from '@/app/components/ConfirmationModal';
import UserDetailsPopUp from '@/app/components/UserDetailsPopUp/UserDetailsPopUp';
interface LearnerTableProps {
    selectedSort: string
    selectedFilterUserStatus: string[]
    selectedFilterLevels: string[];
}

const LearnerTable: React.FC<LearnerTableProps> = ({ selectedSort, selectedFilterUserStatus, selectedFilterLevels }) => {

    const dispatch = useDispatch();

    const [selectedLearner, setSelectedLearner] = useState<User>()
    const [pages, setPages] = useState<number>(1);
    const [searchQuery, setSearchQuery] = useState("");

    const [pageSize, setPageSize] = useState<number>(10);
    const [detailModal, setDetailModal] = useState(false)

    const { users, pagination, loading, error, success } = useSelector((state: RootState) => state.user);

    const fetchData = async (
        page: number = pages,
        rows: number = pageSize,
        sortBy: string = selectedSort,
        query: string = searchQuery,
        filterUserStatus: string[] = selectedFilterUserStatus,
        filterLevels: string[] = selectedFilterLevels
    ) => {
        const formattedFilterUserStatus = Array.isArray(filterUserStatus) ? JSON.stringify(filterUserStatus) : JSON.stringify([]);
        const formattedFilterLevels = Array.isArray(filterLevels) ? JSON.stringify(filterLevels) : JSON.stringify([]);

        dispatch({
            type: FETCH_USERS, payload: {
                page,
                limit: rows,
                search: query,
                userType: 'learner',
                sortBy,
                filterUserStatus: formattedFilterUserStatus,
                filterLevels: formattedFilterLevels
            }
        })
    }

    useEffect(() => {
        fetchData(pages, pageSize, selectedSort, searchQuery, selectedFilterUserStatus, selectedFilterLevels);
    }, [pages, pageSize, searchQuery, selectedSort, selectedFilterUserStatus, selectedFilterLevels]);



    useEffect(() => {
        fetchData(1, 10, selectedSort, '', selectedFilterUserStatus, selectedFilterLevels)
    }, [selectedSort, selectedFilterUserStatus, selectedFilterLevels])

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);

    useEffect(() => {
        if (success) {
            handleSuccess(success);
        }
    }, [success]);


    const handleSuccess = async (message: string) => {
        await successPopup(message);
        dispatch({ type: _USER_CLEAR_SUCCESS })
    };

    const deactivateUser = async (row) => {
        console.log(row.original.id)
        const result = await confirmationModal(`Are you sure you want to ${row.original.activate ? 'deactivate' : 'activate'} the user`);
        if (result) {
            dispatch({ type: ACT_DEACTIVATE_USER, payload: { userId: row.original.id, type: row.original.activate ? 'deactivate' : 'activate' } })
        }
    }

    const deleteUser = async (row) => {
        const result = await confirmationModal(`Are you sure you want to delete the user`);
        if (result) {
            dispatch({ type: DELETE_USER, payload: { userId: row.original.id } })
        }
    }


    const columns = [
        { header: 'Name', accessorKey: 'name' },
        { header: 'Membership ID', accessorKey: 'memberId' },
        { header: 'Joining Date', cell: ({ row }) => <span>{formatDateToMonthDateYear(row.original.enrolmentDate)}</span> },
        { header: 'Enrolled Course', accessorKey: 'enrolledCourse' },
        {
            header: 'Actions', cell: ({ row }) => (
                <TableActionDropdown
                    actions={[
                        {
                            label: 'View Details', onClick: () => {
                                console.log(row.original)
                                setSelectedLearner(row.original)
                                setDetailModal(true)
                            }
                        },
                        { label: row.original.activate ? 'Deactivate User' : 'Activate User', onClick: () => deactivateUser(row) },
                        { label: 'Resend login credentials', onClick: () => dispatch({ type: RESEND_CREDENTIALS, payload: { user: row.original.id } }) },
                        {
                            label: 'Delete User',
                            onClick: async () => {
                              await deleteUser(row); 
                              window.location.reload();
                            }
                          },
                    ]}
                />
            )
        },
    ];

    const handleSearchChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    }, 500);


    const handlePaginationChange = (pageNumber: number, pageSize: number) => {
        setPages(pageNumber);
        setPageSize(pageSize);
    };

    useEffect(() => {
        dispatch({
            type: FETCH_COHORTS, payload: {

            }
        });
    }, [])
    return (
        <>
            {loading && <Loader />}
            {users && pagination && <PaginatedTable<User>
                columns={columns}
                data={users}
                pageCount={pagination?.totalPages}
                fetchData={fetchData}
                handleSearchChange={handleSearchChange}
                handlePaginationChange={handlePaginationChange}
            />}
            {selectedLearner && detailModal && <UserDetailsPopUp
                selectedUser={selectedLearner} visible={detailModal} fetchData={fetchData} handleClose={() => {
                    setDetailModal(false)
                }}
            />}
        </>
    );
};

export default LearnerTable;
