import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import LearnersCard from "./Cards/LearnersCard";
import { SearchIcon } from "@/app/assets/icons/svg";
import Input from "../Input";
import axios from "axios";

interface AssignedMarkerProps {
  formdata: { markers: string[] };
  setAnyAssigned: (hasAssigned: boolean) => void;
}

const AssignedMarker = forwardRef((props: AssignedMarkerProps, ref) => {
  const [cardData, setCardData] = useState<any[]>([]); // To store API response
  const [assignedStatus, setAssignedStatus] = useState<{ [key: string]: boolean }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");

  // Fetch card data from the API
  useEffect(() => {
    const fetchCardData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_EDUMARK_SYSTEMS_URL}/markers`
        );

        // Assuming the response contains the list of learners
        const markers = response.data;
        console.log('markers: ', markers)

        setCardData(markers);
        // Initialize the assigned status based on the API data
        const initialStatus = markers.reduce((acc: { [key: string]: boolean }, learner: any) => {
          acc[learner.id] = learner.isAssigned || false; // Default to false if not provided
          return acc;
        }, {});
        setAssignedStatus(initialStatus);
      } catch (err) {
        console.error("Error fetching card data:", err);
        setError("Failed to fetch data.");
      } finally {
        setLoading(false);
      }
    };

    fetchCardData();
  }, []);

  const handleAssignClick = (id: string) => {
    setAssignedStatus((prevStatus) => {
      const newStatus = !prevStatus[id];
      props.formdata.markers = newStatus
        ? [...props.formdata.markers, id] // Add marker ID if assigned
        : props.formdata.markers.filter((markerId) => markerId !== id); // Remove marker ID if unassigned

      return {
        ...prevStatus,
        [id]: newStatus,
      };
    });
  };

  const handleUnassignAction = () => {
    setAssignedStatus(
      Object.keys(assignedStatus).reduce((acc: { [key: string]: boolean }, id) => {
        acc[id] = false;
        return acc;
      }, {})
    );
    // Clear the markers in formdata
    props.formdata.markers = [];
  };

  useEffect(() => {
    const hasAssigned = Object.values(assignedStatus).some((status) => status);
    props.setAnyAssigned(hasAssigned);
  }, [assignedStatus, props]);

  const filteredData = cardData.filter((card) =>
    card.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useImperativeHandle(ref, () => ({
    handleUnassignAction,
  }));

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <div className="container">
        <Input
          placeholder={"Search by name"}
          error={undefined}
          leftIcon={<img src={SearchIcon} alt="Search" />}
          className="mb-4 cstm-search"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className="row">
          {filteredData.map((card) => (
            <div className="col-md-6" key={card.id}>
              <LearnersCard
                id={card.id}
                name={card.name}
                level={card.level.join(", ")} // Adjust fields based on API response structure
                joinedTime={card.joinedTime}
                assigned={card.assigned}
                completed={card.completed}
                avatar={card.avatar}
                isAssigned={assignedStatus[card.id]}
                handleAssignClick={() => handleAssignClick(card.id)}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
});

export default AssignedMarker;
