import { Col, Spinner } from "react-bootstrap";
import FileUpload from "../FileUpload";
import { CircleCheckIcon, CrossCheckIcon } from "@/app/assets/icons/svg";
import ProgressBar from "../ProgressBar";
import { useEffect, useState } from "react";

interface UploadedFile {
    uploadedAssignment: File[] | [];
}

interface Criteria {
    value?: string;
    isFilledCorrectly?: boolean;
}

interface CheckedCriteria {
    [key: string]: Criteria;
}

interface UploadNewAssignmentProps {
    assessmentId: string | null;
    uploadFile: UploadedFile;
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>, filed: "uploadedAssignment") => void;
    errorMsg: string | null;
    checkedCriteria?: CheckedCriteria | null;
}

const UploadNewAssignment: React.FC<UploadNewAssignmentProps> = ({
    assessmentId,
    uploadFile,
    handleFileChange,
    errorMsg,
    checkedCriteria = null, // Default to null if not provided
}) => {
    const [percentage, setPercentage] = useState(0);
    const [warning, setWarning] = useState(false);
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
      let myTimeout;
      let currentPercentage = percentage; // Local variable to track progress
      let delay = 100; // Initial delay
  
      const incrementPercentage = () => {
          if (loading && !checkedCriteria && currentPercentage < 100) {
              currentPercentage += 1;
              setPercentage(currentPercentage);
  
              // Slow down as percentage increases
              delay = Math.min(100 + (currentPercentage / 100) * 3000, 4000);
  
              myTimeout = setTimeout(incrementPercentage, delay);
          } else {
              clearTimeout(myTimeout);
          }
      };
  
      if (loading && !checkedCriteria) {
          incrementPercentage();
      }
  
      return () => clearTimeout(myTimeout);
  }, [loading, checkedCriteria]); // Dependency on loading and checkedCriteria  

    useEffect(() => {
        if (checkedCriteria) {
            const totalCriteria = Object.keys(checkedCriteria).length;

            let metCriteria = 0;
            for (const key in checkedCriteria) {
                if (checkedCriteria[key]?.isFilledCorrectly) {
                    metCriteria++;
                }
            }

            // setPercentage((metCriteria / totalCriteria) * 100);

            if (totalCriteria - metCriteria > 1) {
                setWarning(true);
            } else {
                setWarning(false);
            }
            setPercentage(100);
            setLoading(false)
        }
    }, [checkedCriteria]);

    const handleFileUploadText = (
        <span>
          Drop files in <i style={{ color: "green", fontWeight: "bold" }}>.docx format</i> to attach
        </span>
      );
      
    return (
        <>
          {/* Assessment ID Section */}
          <Col className="m-3 cstm-lh">
            <span className="txt-lbl">Assessment ID</span>
            <br />
            <span className="txt-data">{assessmentId}</span>
          </Col>
    
          {/* File Upload Section */}
          {uploadFile?.uploadedAssignment.length === 0 && (
            <Col className="m-3 cstm-lh">
              <span className="txt-lbl">Upload Assignment </span>
              <span className="required-star">*</span>
              <br />
              <FileUpload
                name="assignmentBrief"
                value={uploadFile?.uploadedAssignment || []}
                maxFileSize={10 * 1024 * 1024} // 10MB
                onFileChange={(event) => handleFileChange(event, "uploadedAssignment")}
                required
                className="custom-fileupload"
                text={handleFileUploadText}
                errorMsg={errorMsg}
              />
            </Col>
          )}
    
          {/* Progress Bar and Feedback Section */}
          {uploadFile?.uploadedAssignment && uploadFile?.uploadedAssignment.length > 0 && (
            <>
              <Col>
                <ProgressBar percentage={Number(percentage)} label={"Criteria checklist"} />
              </Col>
    
              {/* Loading State */}
              {loading ? (
                <Col className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                  <p>Checking criteria...</p>
                </Col>
              ) : (
                <>
                  {/* Checked Criteria List */}
                  {checkedCriteria &&
                    Object.keys(checkedCriteria).map((key) => (
                      <Col className="mt-4 cstm-lh-upload" key={key}>
                        {checkedCriteria[key]?.isFilledCorrectly ? (
                          <img src={CircleCheckIcon} alt="Check Icon" width="30px" />
                        ) : (
                          <img src={CrossCheckIcon} alt="Cross Icon" width="30px" />
                        )}
                        <span className="txt-data">{key.replace(/_/g, " ")}</span>
                        <br />
                        {!checkedCriteria[key]?.isFilledCorrectly && (
                          <span className="error-span">This field is incorrect or missing.</span>
                        )}
                      </Col>
                    ))}
                  {/* Warning Message */}
                  <Col className="mt-4 ms-4 cstm-lh-upload fs-6">
                    {warning && (
                      <span className="text-warning">
                        Your assignment does not meet one or more requirements. Make necessary corrections to your assignment.
                      </span>
                    )}
                  </Col>
                </>
              )}
            </>
          )}
        </>
      );
}

export default UploadNewAssignment;
