import { CollapseIcon, ExpandIcon, ArrowLeftIcon, CrossIcon, CommonFileCheckIcon, UploadIcon } from "@/app/assets/icons/svg";
import { useEffect, useState } from "react";
import { Col, Modal, Row, Button, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import "./style.css";
import ViewAssignment from "./ViewAssignment";
import UploadNewAssignment from "./UploadNewAssignment";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_LEARNER_UPLOAD_ASSIGNMNET, MARK_ASSIGNMENT, PRE_BRIEF_CHECK, RESUBMIT_FEEDBACK, UPLOAD_LEARNER_ASSIGNMENT } from "@/app/redux/actionTypes";
import { RootState } from "@/app/redux/store";

interface UploadedFile {
    uploadedAssignment: File[] | [];
}

type Marker = {
    id: string;
    name: string;
    levels: number[];
    avatar: string;
};

type Assignment = {
    assessmentId: string;
    code: string;
    title: string;
    date: string;
    label: string;
    description?: string;
    assignmentBriefFile: string;
    assessmentCriteriaFile: string;
    lastModerationDate: string;
    markers: Marker[];
};

interface UploadAssignmentProps {
    isOpen: boolean;
    onClose: () => void;
    item:Assignment | any;
}

const UploadAssignment: React.FC<UploadAssignmentProps> = ({ isOpen, onClose, item }) => {
    const [uploadedFile, setUploadedFile] = useState<UploadedFile>({ uploadedAssignment: [] });
    const [isMaximized, setIsMaximized] = useState(false);
    const [isMarkedAssignmentCalled, setIsMarkedAssignmentCalled] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [payload1, setPayLoad1] = useState<any>({})
    const [payload2, setPayLoad2] = useState<any>({})
    const [step, setStep] = useState(1);
    const dispatch = useDispatch();
    const { loading, success, message, assignmentBriefData, assignmentMarkedData, learnerAssignmentSuccess } = useSelector((state: RootState) => state.uploadAssignment);
    const { user} = useSelector((state: RootState) => state.auth);
    console.log("USER: ", user)
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, field: 'uploadedAssignment') => {
        const selectedFiles = event.target.files;
        if (selectedFiles && selectedFiles.length > 0) {
            const filesArray = Array.from(selectedFiles);
            const invalidFiles = filesArray.filter(file => file.name.split('.').pop()?.toLowerCase() !== 'docx');
            if (invalidFiles.length > 0) {
                setError(`Please upload only .docx files.`);
                setUploadedFile({ uploadedAssignment: [] });
            } else {
                setError(null);
                setUploadedFile({ uploadedAssignment: filesArray });
            }
        } else {
            setUploadedFile({ uploadedAssignment: [] });
            setError(null);
        }
    };

    const toggleMaximize = () => {
        setIsMaximized(prev => !prev);
    };

    const handleUpload = () => {
        if (uploadedFile.uploadedAssignment.length > 0) {
            const formData = new FormData();
            const learnerData = {
                firstName: user?.name,
                lastName: user?.name,
                otherNames: user?.otherName || "",
                learnerNumber: '9192',
                unitCode: item?.code || "",
                unitTitle: item?.title,
                membershipID: user?.memberId,
                centreName: user?.centreName                ,
                centreID: user?.centreId                ,
                submissionDate: item?.date,
                assignmentID: item?.assessmentId
            };
            for (const [key, value] of Object.entries(learnerData)) {
                formData.append(key, value);
            }
            const file = uploadedFile.uploadedAssignment[0];
            if (file) {
                formData.append('file', file);
            }
            const payload = Object.fromEntries(formData.entries());
            if(['resubmission_1','resubmission_2'].includes(item?.assignmentstatus)){
                dispatch({ type: RESUBMIT_FEEDBACK, payload });
            } else {
                console.log('PRE-ASSIGNMENT-BRIEF-CHECK payload: ', payload)
                dispatch({ type: PRE_BRIEF_CHECK, payload });
            }
        }
    };

    useEffect(() => {
        if (uploadedFile.uploadedAssignment.length > 0 && step === 2) {
            handleUpload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedFile, step]);

    useEffect(() => {
        console.log("assignmentBriefData: ", assignmentBriefData)
        if (assignmentBriefData) {
            const {
                fileID,
                imageURLs,
            } = assignmentBriefData;
    
            const {
                Centre_number,
                Student_Declaration,
                membership_id,
                Centre_name,
                Learner_surname,
                Learner_other_names,
                //Unit_code,
                Unit_title,
                Assessment_submission_date,
                Declared_word_count,
            } = assignmentBriefData.briefCheck;
    
            const fieldsCheck = [
                Centre_number,
                Student_Declaration,
                membership_id,
                Centre_name,
                Learner_surname,
                Learner_other_names,
                //Unit_code,
                Unit_title,
                Assessment_submission_date,
                Declared_word_count
            ];
            console.log("fieldsCheck: ", fieldsCheck)
            const errorFields = fieldsCheck.filter(field => !field?.isFilledCorrectly);
            console.log('errorFields: ',errorFields)
            if (errorFields.length > 0) {
                const message = `Please check the following fields: ${errorFields.map(field => field.value).join(', ')}`;
                Swal.fire({
                    title: "Upload Error",
                    icon: 'error',
                    text: message,
                    confirmButtonText: 'Okay',
                });
                setStep(2);
                setUploadedFile({uploadedAssignment:[]})
                dispatch({ type: CLEAR_LEARNER_UPLOAD_ASSIGNMNET });
                setPayLoad1({})
                setPayLoad2({})
                return;
            }
    
            const payload = {
                file_id: fileID,
                image_urls: imageURLs,
                firstName: Learner_surname.value,
                lastName: Learner_surname.value,
                otherNames: "",
                learnerNumber: membership_id.value,
                //unitCode: Unit_code.value,
                unitTitle: Unit_title.value,
                membershipID: membership_id.value,
                centreName: Centre_name.value,
                centreID: Centre_number.value,
                submissionDate: Assessment_submission_date.value,
                declaredWordCount: Declared_word_count.value,
                studentDeclaration: Student_Declaration.value
            };
            console.log('setting payload 1', payload)
            setPayLoad1(payload);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignmentBriefData]);
    

    useEffect(()=>{
        if(assignmentMarkedData){
            setIsMarkedAssignmentCalled(false);
            setPayLoad2(assignmentMarkedData)
            const formData = new FormData();
            const file = uploadedFile.uploadedAssignment[0];
            if (file) {
                formData.append('uploadedAssignmentFile', file);
            }
            if(assignmentBriefData?.briefCheck){
                formData.append('assignmentBriefDetails', JSON.stringify(assignmentBriefData?.briefCheck));
            }
            if(item.assessmentId){
                formData.append('assessmentId', item?.assessmentId);
            }
            if(assignmentMarkedData){
                formData.append('file_id', assignmentMarkedData?.new_file_id);
            }
            const payload = Object.fromEntries(formData.entries());
            dispatch({ type: UPLOAD_LEARNER_ASSIGNMENT, payload });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[assignmentMarkedData])

    const handleSubmit = (e: React.FormEvent) => {
        console.log('submit')
        e.preventDefault();
        if (step === 2 && uploadedFile.uploadedAssignment.length > 0) {
            setIsMarkedAssignmentCalled(true);
            payload1["unitCode"] = ""
            dispatch({ type: MARK_ASSIGNMENT, payload:payload1 });
        }
    };

    useEffect(() => {
        if (success === false && step === 2) {
            Swal.fire({
                title: "Error Submitting",
                icon: 'error',
                text: `${message}`,
                confirmButtonText: 'Okay',
            });
            close();
        }else if(success && learnerAssignmentSuccess && step === 2){
            setIsSubmitted(true);
        }else if (success && isSubmitted) {
            dispatch({ type: CLEAR_LEARNER_UPLOAD_ASSIGNMNET });
            setPayLoad1({})
            setPayLoad2({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success, learnerAssignmentSuccess, dispatch]);

    const handleNext = () => {
        if (step === 1) {
            setStep(2);
        }
    };

    const close = () => {
        setStep(1);
        onClose();
        setUploadedFile({uploadedAssignment:[]})
        dispatch({ type: CLEAR_LEARNER_UPLOAD_ASSIGNMNET });
        setPayLoad1({})
        setPayLoad2({})
    };

    return (
        <>
            <Modal show={isOpen && !isSubmitted} onHide={close} className={isMaximized ? 'maximized' : ''}>
                <Modal.Header>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        {step > 1 && (
                            <Button variant="link" onClick={() => setStep(step - 1)} style={{ padding: "10px", color: "black" }}>
                                <img src={ArrowLeftIcon} alt='' />
                            </Button>
                        )}
                        <span style={{ flex: 1, fontWeight: 'bold' }}>
                            {step === 1 ? `Assignment ${item?.assessmentId}` : step === 2 ? "Upload Assignment" : ""}
                        </span>
                        <Button variant="link" onClick={toggleMaximize} style={{ padding: "10px", color: "black" }}>
                            {isMaximized ? <img src={CollapseIcon} alt='' /> : <img src={ExpandIcon} alt='' />}
                        </Button>
                        <Button variant="link" onClick={close} style={{ padding: "10px", color: "black" }}>
                            <img src={CrossIcon} alt="" />
                        </Button>
                    </div>
                    <hr />
                </Modal.Header>
                <Modal.Body>
                    {step === 1 && item && (
                        <ViewAssignment data={item} />
                    )}
                    {step === 2 && (
                        <UploadNewAssignment
                            assessmentId={item?.assessmentId}
                            uploadFile={uploadedFile}
                            handleFileChange={handleFileChange}
                            errorMsg={error}
                            checkedCriteria={assignmentBriefData?.briefCheck}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Row className='w-100'>
                        <Col className='d-flex text-end'>
                            <Button variant="light" className="bg-none text-primary me-2 ms-auto" onClick={close}>
                                Cancel
                            </Button>
                            {step === 1 && (
                                <Button className="bg-none text-white me-2" onClick={handleNext}>
                                    <img src={UploadIcon} alt="" /> Upload Assignment
                                </Button>
                            )}
                            {step === 2 && (
                                <Button 
                                    variant={uploadedFile.uploadedAssignment.length ? "primary" : "secondary"} 
                                    onClick={handleSubmit}
                                    disabled={!uploadedFile.uploadedAssignment.length || !assignmentBriefData}
                                >
                                    {!isMarkedAssignmentCalled && <span>Submit</span>}
                                    {isMarkedAssignmentCalled && <Spinner animation="border" variant="light" />}

                                </Button>
                            )}
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
            <Modal show={isSubmitted} className='success-modal'>
                <Row className='w-100'>
                    <Col className='col-md-12 text-end'>
                        <Button variant="link" onClick={close} style={{ padding: "10px", color: "black" }}>
                            <img src={CrossIcon} alt="" />
                        </Button>
                    </Col>
                    <Col className='col-md-12 text-center'>
                        <img src={CommonFileCheckIcon} alt='' className='cstm-img' />
                    </Col>
                    <Col className='col-md-12 text-center ctsm-span-col mb-5'>
                        <span>Well Done! Your assignment {item?.assessmentId} has been submitted successfully</span>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default UploadAssignment;