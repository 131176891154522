import React, { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import Input from '../Input';
import Select from '../Select';
import TextArea from '../TextArea';
import FileUpload from '../FileUpload';

interface FormData {
  assessmentId: string;
  unitCode: string;
  unitTitle: string;
  level: string;
  centerNumber: string;
  centerName: string;
  releasedDate: string;
  lastModeration: string;
  dueDate: string;
  description: string;
  assignmentBriefFiles: File[] | [];
  assignmentCriteriaFiles: File[] | [];
}

interface CreateAssignmentProps {
  formData: FormData;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => void;
  handleFileChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    field: 'assignmentBriefFiles' | 'assignmentCriteriaFiles'
  ) => void;
  readOnly?: boolean;
}

const levelOptions = [
  { value: '3', label: 'Level 3' },
  { value: '5', label: 'Level 5' },
  { value: '7', label: 'Level 7' },
];

const CreateAssignment: React.FC<CreateAssignmentProps> = ({
  formData,
  handleChange,
  handleFileChange,
  readOnly,
}) => {
  // Prevent default to allow drop
  const handleDragOver = useCallback((e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  // Handle dropping files
  const handleDrop = useCallback(
    (
      e: React.DragEvent<HTMLElement>,
      field: 'assignmentBriefFiles' | 'assignmentCriteriaFiles'
    ) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        // Reuse your existing file handler by mocking a change event
        handleFileChange(
          {
            target: { files: e.dataTransfer.files } as HTMLInputElement,
          } as React.ChangeEvent<HTMLInputElement>,
          field
        );

        // Clear the drag data
        e.dataTransfer.clearData();
      }
    },
    [handleFileChange]
  );

  return (
    <>
      <span style={{ color: 'grey' }}>
        Required fields are marked with an asterisk
      </span>
      <span className="required-star">*</span>

      <Form.Group controlId="formAssessmentId">
        <Form.Label>
          Assessment ID<span className="required-star">*</span>
        </Form.Label>
        <Input
          type="text"
          name="assessmentId"
          value={formData.assessmentId}
          onChange={handleChange}
          required
          placeholder=""
          error={undefined}
          className="custom-input"
          readOnly={readOnly}
        />
      </Form.Group>

      <Form.Group controlId="formUnitCode">
        <Form.Label>
          Unit Code<span className="required-star">*</span>
        </Form.Label>
        <Input
          type="text"
          name="unitCode"
          value={formData.unitCode}
          onChange={handleChange}
          required
          placeholder=""
          error={undefined}
          className="custom-input"
          readOnly={readOnly}
        />
      </Form.Group>

      <Form.Group controlId="formUnitTitle">
        <Form.Label>
          Unit Title<span className="required-star">*</span>
        </Form.Label>
        <Input
          type="text"
          name="unitTitle"
          value={formData.unitTitle}
          onChange={handleChange}
          required
          placeholder=""
          error={undefined}
          className="custom-input-title"
          readOnly={readOnly}
        />
      </Form.Group>

      <Form.Group controlId="formLevel">
        <Form.Label>
          Level <span className="required-star">*</span>
        </Form.Label>
        <Select
          className="custom-input"
          name="level"
          value={formData.level}
          onChange={handleChange}
          required
          rightIcon={
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="#8A8A8A"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          }
          placeholder="Select Level"
          error={undefined}
          options={levelOptions}
          readOnly={readOnly}
        />
      </Form.Group>

      <Form.Group controlId="formCenterNumber">
        <Form.Label>
          Center Number<span className="required-star">*</span>
        </Form.Label>
        <Input
          type="text"
          name="centerNumber"
          value={formData.centerNumber || '1153'}
          onChange={handleChange}
          required
          placeholder=""
          error={undefined}
          className="custom-input"
          readOnly={readOnly}
        />
      </Form.Group>

      <Form.Group controlId="formCenterName">
        <Form.Label>Center Name</Form.Label>
        <Input
          type="text"
          name="centerName"
          value={formData.centerName || 'WE-ARE-HR'}
          onChange={handleChange}
          placeholder=""
          error={undefined}
          className="custom-input"
          readOnly={readOnly}
        />
      </Form.Group>

      <Form.Group controlId="formReleasedDate">
        <Form.Label>Released Date </Form.Label>
        <Input
          type="date"
          name="releasedDate"
          value={
            formData.releasedDate || new Date().toISOString().split('T')[0]
          }
          onChange={handleChange}
          placeholder=""
          error={undefined}
          className="custom-date-input"
          readOnly={readOnly}
        />
      </Form.Group>

      <Form.Group controlId="formLastModeration">
        <Form.Label>Last Moderation (Optional)</Form.Label>
        <Input
          type="date"
          name="lastModeration"
          value={formData.lastModeration || ''}
          onChange={handleChange}
          placeholder=""
          error={undefined}
          className="custom-date-input"
          readOnly={readOnly}
        />
      </Form.Group>

      <Form.Group controlId="formDueDate">
        <Form.Label>Due Date (Optional)</Form.Label>
        <Input
          type="date"
          name="dueDate"
          value={formData.dueDate || ''}
          onChange={handleChange}
          placeholder=""
          error={undefined}
          className="custom-date-input"
          readOnly={readOnly}
        />
      </Form.Group>

      <Form.Group controlId="formDescription">
        <Form.Label>Description (Optional)</Form.Label>
        <TextArea
          name="description"
          value={formData.description || ''}
          onChange={handleChange}
          placeholder=""
          error={undefined}
          className="custom-textarea"
          readOnly={readOnly}
        />
      </Form.Group>

      {/* Assignment Brief with drag-and-drop */}
      <Form.Group
        controlId="formAssignmentBrief"
        onDragOver={handleDragOver}
        onDrop={(e) => handleDrop(e, 'assignmentBriefFiles')}
      >
        <Form.Label>
          Upload Assignment<span className="required-star">*</span>
        </Form.Label>
        <FileUpload
          name="assignmentBrief"
          value={formData.assignmentBriefFiles}
          maxFileSize={10 * 1024 * 1024}
          onFileChange={(event) =>
            handleFileChange(event, 'assignmentBriefFiles')
          }
          required
          className="custom-fileupload"
          readOnly={readOnly}
          text="Drop files to attach"
        />
      </Form.Group>

      {/* Assignment Criteria with drag-and-drop */}
      <Form.Group
        controlId="formAssignmentCriteria"
        onDragOver={handleDragOver}
        onDrop={(e) => handleDrop(e, 'assignmentCriteriaFiles')}
      >
        <Form.Label>Upload Assignment Mark Sheet (Optional)</Form.Label>
        <FileUpload
          name="assignmentCriteria"
          value={formData.assignmentCriteriaFiles || []}
          maxFileSize={10 * 1024 * 1024}
          onFileChange={(event) =>
            handleFileChange(event, 'assignmentCriteriaFiles')
          }
          className="custom-fileupload"
          readOnly={readOnly}
          text="Drop files to attach"
        />
      </Form.Group>
    </>
  );
};

export default CreateAssignment;
