export const profileFieldsByUserType = {
    learner: [
        { name: 'email', label: 'Email', placeholder: 'Enter Email', required: true, prefilled: true, type: 'text' },
        { name: 'memberId', label: 'Member ID', placeholder: 'Enter Member ID', required: true, prefilled: true, type: 'text' },
        { name: 'centreId', label: 'Center ID', placeholder: 'Enter Centre ID', required: true, prefilled: true, type: 'text' },
        { name: 'courseId', label: 'Course ID', placeholder: 'Enter Course ID', required: true, prefilled: true, type: 'text' },
        { name: 'enrolledCourse', label: 'Enrolled Course', placeholder: 'Enter Enrolled Course', required: true, prefilled: true, type: 'text' },
        { name: 'enrollmentDate', label: 'Enrolment Date', placeholder: 'Enter Enrolment Date', required: true, prefilled: true, type: 'text' },
        { name: 'assignedTutorName', label: 'Assigned Tutor', placeholder: 'Enter Enrolment Date', required: true, prefilled: true, type: 'text' },
        { name: 'phone', label: 'Phone Number', placeholder: 'Enter Phone Number', required: false, prefilled: false, type: 'text' },
    ],
    educator: [
        { name: 'email', label: 'Email', placeholder: 'Enter Email', required: true, prefilled: true, type: 'text' },
        { name: 'userType', label: 'Role', placeholder: 'Enter Membership ID', required: true, prefilled: true, type: 'text' },
        { name: 'centreId', label: 'Center ID', placeholder: 'Enter Centre ID', required: true, prefilled: true, type: 'text' },
        { name: 'phone', label: 'Phone Number', placeholder: 'Enter Phone Number', required: false, prefilled: false, type: 'text' },
    ],
    supervisor: [
        { name: 'email', label: 'Email', placeholder: 'Enter Email', required: true, prefilled: true, type: 'text' },
        { name: 'userType', label: 'Role', placeholder: 'Enter Membership ID', required: true, prefilled: true, type: 'text' },
        { name: 'centreId', label: 'Center ID', placeholder: 'Enter Centre ID', required: true, prefilled: true, type: 'text' },
        { name: 'phone', label: 'Phone Number', placeholder: 'Enter Phone Number', required: false, prefilled: false, type: 'text' },
    ],
    admin: [
        { name: 'email', label: 'Email', placeholder: 'Enter Email', required: true, prefilled: true, type: 'text' },
        { name: 'userType', label: 'Role', placeholder: 'Enter Membership ID', required: true, prefilled: true, type: 'text' },
        { name: 'centreId', label: 'Center ID', placeholder: 'Enter Centre ID', required: true, prefilled: true, type: 'text' },
        { name: 'phone', label: 'Phone Number', placeholder: 'Enter Phone Number', required: false, prefilled: false, type: 'text' },
    ],

}


export const assUserFieldByUserType = {
    learner: [
        { name: 'name', label: 'Full Name', placeholder: 'Enter Name', required: true, prefilled: false, type: 'text' },
        { name: 'othername', label: 'Other Name', placeholder: 'Enter Other Name', required: false, prefilled: false, type: 'text' },
        { name: 'email', label: 'Learners Email', placeholder: 'Enter Email', required: true, prefilled: false, type: 'text' },
        { name: 'memberId', label: 'CIPD Membership ID', placeholder: 'Enter Membership ID', required: false, prefilled: false, type: 'text' },
        { name: 'centreId', label: 'Centre ID', placeholder: 'Enter Center Number', required: true, prefilled: false, type: 'text'},
        { name: 'centreName', label: 'Centre Name', placeholder: 'Enter Center Name', required: true, prefilled: false, type: 'text'},
        { name: 'enrolledCourse', label: 'Course Enrolled', placeholder: 'Enter enrolled Course', required: true, prefilled: false, type: 'text' },
        { name: 'levels', label: 'Level', placeholder: 'Select Level', required: true, prefilled: false, type: 'level' },
        { name: 'cohorts', label: 'Cohort', placeholder: 'Select Cohort', required: true, prefilled: false, type: 'cohort' },
        { name: 'enrolmentDate', label: 'Joining Date', placeholder: '', required: true, prefilled: false, type: 'date' },
        { name: 'activate', label: 'Activate Learner Account', placeholder: '', required: true, prefilled: false, type: 'switch' },
    ],
    educator: [
        { name: 'name', label: 'Full Name', placeholder: 'Enter Name', required: true, prefilled: false, type: 'text' },
        { name: 'othername', label: 'Other Name', placeholder: 'Enter Other Name', required: false, prefilled: false, type: 'text' },
        { name: 'email', label: 'Educator Email', placeholder: 'Enter Email', required: true, prefilled: false, type: 'text' },
        { name: 'memberId', label: 'Membership ID', placeholder: 'Enter Membership ID', required: false, prefilled: false, type: 'text' },
        { name: 'centreName', label: 'Centre Name', placeholder: 'Enter Center Name', required: true, prefilled: false, type: 'text'},
        { name: 'levels', label: 'Level', placeholder: 'Select Level', required: true, prefilled: false, type: 'level' },
        { name: 'cohorts', label: 'Cohort', placeholder: 'Select Cohort', required: true, prefilled: false, type: 'cohort' },
        { name: 'enrolmentDate', label: 'Joining Date', placeholder: '', required: true, prefilled: false, type: 'date' },
        { name: 'accessToAssignments', label: 'Access to Assignment Management', placeholder: '', required: true, prefilled: false, type: 'switch' },
        { name: 'activate', label: 'Activate Learner Account', placeholder: '', required: true, prefilled: false, type: 'switch' },
    ],
    supervisor: [
        { name: 'name', label: 'Full Name', placeholder: 'Enter Full Name', required: true, prefilled: false, type: 'text' },
        { name: 'otherName', label: 'Other Name', placeholder: 'Enter Other Name', required: true, prefilled: false, type: 'text' },
        { name: 'email', label: 'Email', placeholder: 'Enter Email', required: true, prefilled: false, type: 'text' },
        { name: 'enrolmentDate', label: 'Joining Date', placeholder: '', required: true, prefilled: false, type: 'date' },
        { name: 'centreId', label: 'Centre ID', placeholder: 'Enter Center Number', required: true, prefilled: false, type: 'text'},
        { name: 'centreName', label: 'Centre Name', placeholder: 'Enter Center Name', required: true, prefilled: false, type: 'text' },
    ],
}


export const updateProfileFields = [
    { name: 'email', label: 'Email', placeholder: 'Enter Email', required: true, prefilled: true, type: 'text' },
    { name: 'memberid', label: 'Member ID', placeholder: 'Enter Member ID', required: true, prefilled: true, type: 'text' },
    { name: 'centerid', label: 'Center ID', placeholder: 'Enter Center ID', required: true, prefilled: true, type: 'text' },
    { name: 'courseid', label: 'Course ID', placeholder: 'Enter Course ID', required: true, prefilled: true, type: 'text' },
    { name: 'enrolledcourse', label: 'Enrolled Course', placeholder: 'Enter Enrolled Course', required: true, prefilled: true, type: 'text' },
    { name: 'enrollmentdate', label: 'Enrolment Date', placeholder: 'Enter Enrolment Date', required: true, prefilled: true, type: 'text' },
    { name: 'assignedtutor', label: 'Assigned Tutor', placeholder: 'Enter Enrolment Date', required: true, prefilled: true, type: 'text' },
    { name: 'phonenumber', label: 'Phone Number', placeholder: 'Enter Phone Number', required: false, prefilled: false, type: 'text' },
];


export const daysOfWeek = [
    { dayName: 'monday', label: 'Monday' },
    { dayName: 'tuesday', label: 'Tuesday' },
    { dayName: 'wednesday', label: 'Wednesday' },
    { dayName: 'thursday', label: 'Thursday' },
    { dayName: 'friday', label: 'Friday' },
    { dayName: 'saturday', label: 'Saturday' },
    { dayName: 'sunday', label: 'Sunday' },
];


export const learnersData = [
    {
        name: 'John Doe',
        status: 'active',
        memberId: '12345678',
        date: '2024-07-01',
        level: 'level 1',
    },
    {
        name: 'Jane Smith',
        status: 'inactive',
        memberId: '23456789',
        date: '2024-07-01',
        level: 'level 2',
    },
    {
        name: 'Emily Johnson',
        status: 'deleted',
        memberId: '34567890',
        date: '2024-07-01',
        level: 'level 4',
    },
    {
        name: 'Michael Brown',
        status: 'active',
        memberId: '45678901',
        date: '2024-07-01',
        level: 'level 2',
    },
    {
        name: 'Jessica Davis',
        status: 'inactive',
        memberId: '56789012',
        date: '2024-07-01',
        level: 'level 1',
    },
    {
        name: 'David Wilson',
        status: 'deleted',
        memberId: '67890123',
        date: '2024-07-01',
        level: 'level 4',
    },
    {
        name: 'Sarah Miller',
        status: 'active',
        memberId: '78901234',
        date: '2024-07-01',
        level: 'level 1',
    },
    {
        name: 'Chris Moore',
        status: 'inactive',
        memberId: '89012345',
        date: '2024-07-01',
        level: 'level 2',
    },
    {
        name: 'Amanda Taylor',
        status: 'deleted',
        memberId: '90123456',
        date: '2024-07-01',
        level: 'level 4',
    },
    {
        name: 'Daniedccdccdl Anderson',
        status: 'active',
        memberId: '01234567',
        date: '2024-07-01',
        level: 'level 1',
    },
];


export const levels = [
    { id: 'Level 1', value: 'Level 1' },
    { id: 'Level 2', value: 'Level 2' },
    { id: 'Level 3', value: 'Level 3' },
    { id: 'Level 4', value: 'Level 4' },
    { id: 'Level 5', value: 'Level 5' },
    { id: 'Level 6', value: 'Level 6' }
];