import React, { useEffect, useState } from "react";
import Input from "../Input";
import IMAGES from "@/app/utils/Images";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_COHORT_LEARNERS, FETCH_COHORTS } from "@/app/redux/actionTypes";
import { RootState } from "@/app/redux/store";
import LearnerList from "./LearnerList";

interface AssignCohortProps {
  formData: { cohorts: string[]; learners: string[] };
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any
  ) => void;
}

const AssignCohort: React.FC<AssignCohortProps> = ({ formData, handleChange }) => {
  const dispatch = useDispatch();
  const { cohorts, mappedLearners } = useSelector((state: RootState) => state.cohort);
  const [cohortData, setCohortData] = useState<any[]>([]);
  const [checked, setChecked] = useState<string | null>(null);
  const [learners, setLearners] = useState<{ id: string; name: string; memberId: string }[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  // Fetch initial cohorts
  useEffect(() => {
    dispatch({ type: FETCH_COHORTS, payload: { page: 1, limit: 10, search: "" } });
  }, [dispatch]);

  // Fetch learners when a cohort is selected
  useEffect(() => {
    if (checked) {
      dispatch({
        type: FETCH_COHORT_LEARNERS,
        payload: { cohortId: checked, userType: "learner" },
      });
    }
  }, [checked, dispatch]);

  // Update cohort data when cohorts state changes
  useEffect(() => {
    if (cohorts) {
      const cohortDetailList = cohorts.map((item) => ({
        id: item.id,
        name: item.name,
        learners: item.learners || 0,
        icon: IMAGES.COHORT_ICON,
        level: item.course.id,
      }));
      setCohortData(cohortDetailList);
    }
  }, [cohorts]);

  // Update learners when mappedLearners changes
  useEffect(() => {
    if (mappedLearners) {
      const items = mappedLearners.map((item) => ({
        id: item.id,
        name: item.name,
        memberId: item.memberId,
      }));
      setLearners(items);
      // Not automatically updating formData.learners here to avoid double or unwanted entries.
    }
  }, [mappedLearners]);

  // Handle cohort checkbox change
  const handleCheckboxChange = (cohortId: string) => {
    const newChecked = checked === cohortId ? null : cohortId;
    setChecked(newChecked);

    // Update formData.cohorts without duplicates
    let updatedCohorts = formData.cohorts.filter((id) => id !== cohortId);
    if (newChecked) {
      updatedCohorts = [...updatedCohorts, cohortId];
    }

    handleChange({
      target: {
        name: "cohorts",
        value: updatedCohorts,
      },
    });

    // If cohort is deselected, also clear the selected learners
    if (!newChecked) {
      handleChange({
        target: {
          name: "learners",
          value: [],
        },
      });
    }
  };

  // Filter cohorts based on search query
  const filteredCohorts = cohortData.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="cohort-modal m-2">
      <div className="row">
        {/* Cohort List */}
        <div className={`col-md-6 col-12 ${checked ? "border-end" : ""}`}>
          <Input
            leftIcon={<img src={IMAGES.SEARCH_LIGHT} alt="Search Icon" />}
            placeholder="Search by cohort name"
            type="text"
            className="search-cohort-input"
            error={null}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="d-flex flex-column my-4 gap-4">
            {filteredCohorts.map((item) => (
              <div
                className={`cohort-card p-4 ${item.id === checked ? "cohort-selected-card" : ""}`}
                key={item.id}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="card-title d-flex align-items-center gap-4">
                    <img
                      src={item.icon}
                      className="p-2 icon rounded-circle"
                      alt="Cohort Icon"
                    />
                    <div>
                      <label className="title">{item.name}</label>
                    </div>
                  </div>
                  <img src={IMAGES.RIGHT_ARROW} alt="Right Arrow" />
                </div>
                <hr className="card-divider" />
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-4">
                    <div className="card-title cohort-detail">
                      <label className="d-block">{`Total learners: ${item.learners}`}</label>
                      <label>Centre Name: We-Are-HR</label>
                    </div>
                  </div>
                  <input
                    type="checkbox"
                    checked={checked === item.id}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Learner List */}
        {checked && learners.length > 0 && (
          <div className="col-md-6 col-12 px-3">
            <div className="cohort-details-assignment d-flex justify-content-between align-items-center px-lg-3 px-0">
              <div className="card-title d-flex align-items-center h-100">
                <div>
                  <label className="title">
                    {cohortData.find((c) => c.id === checked)?.name}
                  </label>
                </div>
              </div>
              <div className="card-title d-flex align-items-center h-100">
                <div>
                  <label className="card-heading h-100">Total no. of Learners</label>
                  <label className="title">{learners.length}</label>
                </div>
              </div>
            </div>
            <LearnerList
              learners={learners}
              selectedLearners={formData.learners}
              onSelectedChange={(selectedIds) => {
                // Ensure no duplicates before setting
                const uniqueLearners = Array.from(new Set(selectedIds));
                handleChange({
                  target: {
                    name: "learners",
                    value: uniqueLearners,
                  },
                });
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AssignCohort;
