import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import Input from './Input';
import Button from '@components/Button';
import COLORS from '../utils/Colors';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { coHortSchema } from '../modules/validations/validations';
import ErrorField from './ErrorField';
import Asterisk from './Asterisk';
import DropdownWithSearchAndSelectAll from './SearchableDropdown';
import { useDispatch, useSelector } from 'react-redux';
import {
  _USER_CLEAR_SUCCESS,
  CREATE_COHORT,
  FETCH_LEVELS,
  FETCH_USERS_FOR_COHORT,
} from '../redux/actionTypes';
import { RootState } from '../redux/store';
import { toAbsoluteUrl } from '../modules/helpers/assetHelper';
import Swal from 'sweetalert2';
import Loader from './Loading';

type Props = {
  visible: boolean;
  handleClose: () => void;
  onSubmit: () => void;
};

const AddCohort: React.FC<Props> = ({ visible, handleClose }) => {
  const [show, setShow] = useState<boolean>(false);

  // Pull data from Redux
  const { learners, educators, success, loading } = useSelector(
    (state: RootState) => state.user
  );
  const { levels } = useSelector((state: RootState) => state.cohort);

  const dispatch = useDispatch();

  useEffect(() => {
    setShow(visible);
  }, [visible]);

  // Fetch levels on mount
  useEffect(() => {
    dispatch({ type: FETCH_LEVELS });
  }, [dispatch]);

  // If user picks a level, fetch educators & learners for that level
  const fetchUsers = (levelId: string) => {
    dispatch({
      type: FETCH_USERS_FOR_COHORT,
      payload: {
        userType: ['educator', 'learner'],
        level: levelId,
      },
    });
  };

  // Show success popup if cohort creation succeeded
  useEffect(() => {
    if (success) {
      Swal.fire({
        html: `<p style='font-size: 16px;'>${success}<br/></p>`,
        imageUrl: `${toAbsoluteUrl('/media/modal/confirm.png')}`,
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch({ type: _USER_CLEAR_SUCCESS });
          handleClose();
          // Full page reload here:
          window.location.reload();
        }
      });
    }
  }, [success, dispatch, handleClose]);  

  // Transform educators/learners so "level" is a string for display
  const educatorsWithLevelStrings = useMemo(() => {
    return educators.map((ed: any) => {
      if (ed.level && typeof ed.level === 'object') {
        return {
          ...ed,
          level: ed.level.name || '',
        };
      }
      return ed;
    });
  }, [educators]);

  const learnersWithLevelStrings = useMemo(() => {
    return learners.map((lr: any) => {
      if (lr.level && typeof lr.level === 'object') {
        return {
          ...lr,
          level: lr.level.name || '',
        };
      }
      return lr;
    });
  }, [learners]);

  const labelStyle: React.CSSProperties = {
    fontSize: '14px',
    color: COLORS.BLACK,
    lineHeight: '20px',
  };

  const headers = ['Label', 'Email', 'Level'];

  // Callback for educators
  const handleEducatorsChange = useCallback(
    (newSelectedUsers: any, setFieldValue: any) => {
      setFieldValue('educatorIds', newSelectedUsers);
    },
    []
  );

  // Callback for learners
  const handleLearnerChange = useCallback(
    (newSelectedUsers: any, setFieldValue: any) => {
      setFieldValue('learnerIds', newSelectedUsers);
    },
    []
  );

  return (
    <Modal size="lg" centered backdrop="static" show={show} onHide={handleClose}>
      {loading && <Loader />}
      <Modal.Header closeButton>
        <Modal.Title>Add New Cohort</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="text-muted fs-6">
          Required fields are marked with an asterisk <Asterisk />
        </span>
        <div className="row">
          <Formik
            initialValues={{
              name: '',
              level: '',
              educatorIds: [],
              learnerIds: [],
            }}
            validationSchema={coHortSchema}
            onSubmit={(values) => {
              // Just send the ID as "level"
              // so the backend can do findUnique({ where: { id: level } })
              dispatch({
                type: CREATE_COHORT,
                payload: {
                  values, // level is the ID (e.g. '4fd86ef0-1544-...')
                },
              });
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <>
                <div className="col-12 col-sm-12 col-lg-7 pt-3">
                  <Input
                    onChange={handleChange('name')}
                    onBlur={handleBlur('name')}
                    value={values.name}
                    label="Cohort Name"
                    name="name"
                    type="text"
                    placeholder=""
                    error={errors.name && touched.name ? errors.name : null}
                  />

                  <h6 style={labelStyle} className="form-check-label mb-2 mt-2">
                    Level
                  </h6>
                  <Select
                    name="level"
                    sx={{ width: '100%' }}
                    // Once user picks a level, fetch related educators/learners
                    onBlur={(e) => fetchUsers(e.target.value as string)}
                    value={values.level}
                    onChange={handleChange}
                  >
                    {levels.map((item: any) => (
                      // Each <MenuItem> has item.id as the value
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <ErrorField
                    error={errors.level && touched.level ? errors.level : null}
                  />
                </div>

                <div className="col-12 col-sm-12 col-lg-9 pb-3">
                  <h6 style={labelStyle} className="form-check-label mb-2 mt-2">
                    Select Educators
                  </h6>
                  <DropdownWithSearchAndSelectAll
                    options={educatorsWithLevelStrings}
                    headers={headers}
                    selectedOptions={values.educatorIds}
                    onSelectionChange={(newSelectedUsers: any) =>
                      handleEducatorsChange(newSelectedUsers, setFieldValue)
                    }
                  />
                  <ErrorField
                    error={
                      touched.educatorIds && errors.educatorIds
                        ? errors.educatorIds
                        : null
                    }
                  />

                  <h6 style={labelStyle} className="form-check-label mb-2 mt-2">
                    Select Learners
                  </h6>
                  <DropdownWithSearchAndSelectAll
                    options={learnersWithLevelStrings}
                    headers={headers}
                    selectedOptions={values.learnerIds}
                    onSelectionChange={(newSelectedUsers: any) =>
                      handleLearnerChange(newSelectedUsers, setFieldValue)
                    }
                  />
                  <ErrorField
                    error={
                      errors.learnerIds && touched.learnerIds
                        ? errors.learnerIds
                        : null
                    }
                  />
                </div>

                <Modal.Footer style={{ position: 'sticky' }}>
                  <div className="column">
                    <Button
                      label="Cancel"
                      backgroundColor={COLORS.WHITE}
                      onClick={handleClose}
                      labelStyle={{
                        color: COLORS.PRIMARY,
                        fontSize: '14px',
                      }}
                    />
                    <Button
                      className="ms-2"
                      label="Create"
                      backgroundColor={COLORS.PRIMARY}
                      onClick={handleSubmit}
                      style={{ height: 40 }}
                      labelStyle={{ color: COLORS.WHITE, fontSize: '14px' }}
                    />
                  </div>
                </Modal.Footer>
              </>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddCohort;