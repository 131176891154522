import Button from '@/app/components/Button'
import OTPInput from '@/app/components/OtpInput/OtpInput'
import { RootState } from '@/app/redux/store'
import COLORS from '@/app/utils/Colors'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import ForgetPasswordWrapper from './ForgetPasswordWrapper'
import { useLocation, useNavigate } from 'react-router-dom'
import { CLEAR_SUCCESS, SEND_VERIFY_OTP_RESET_PASSWORD } from '@/app/redux/actionTypes'

type Props = {}

const VerifyOtp = (props: Props) => {

    const location = useLocation();

    const { email } = location.state;

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { step } = useSelector((state: RootState) => state.auth)

    const [otp, setOtp] = useState<string>('');

    const handleOtpChange = (otp: string) => {
        setOtp(otp);
    };

    useEffect(() => {
        if (step === 3) {
            setTimeout(() => {
                dispatch({ type: CLEAR_SUCCESS })
                navigate('/forgetEnterPassword', { state: { email: email, otp: otp } })
            }, 300);
        }
        if (step === 1) {
            setTimeout(() => {
                dispatch({ type: CLEAR_SUCCESS })
                navigate(`../forget-password`, { replace: true });
            }, 300);
        }
    }, [step])

    const handleSubmit = () => {
        dispatch({
            type: SEND_VERIFY_OTP_RESET_PASSWORD, payload: {
                email: email,
                type: 'resetpassword',
                step: 2,
                otpCode: otp
            }
        })
    }
    const handleResend = () => {
        dispatch({
            type: SEND_VERIFY_OTP_RESET_PASSWORD, payload: {
                email: email,
                type: 'resetpassword',
                step: 3,
            }
        })
    }

    return (
        <ForgetPasswordWrapper
            heading="Enter the One-Time Password (OTP)"
            description={`We’ve sent a One-Time Password (OTP) to your email address ${email}`}
        >
            <>
                <div className='d-flex flex-column align-items-center'>
                    <div className='my-3'>
                        <OTPInput length={4} onChange={handleOtpChange} />
                    </div>
                    <Button
                        label={'Verify OTP'}
                        backgroundColor={otp.length >= 4 ? COLORS.PRIMARY : COLORS.GRAY}
                        className={'mt-2 loginButton w-100'}
                        onClick={handleSubmit}
                        disabled={otp.length < 4 ? true : false}
                    />
                </div>
                <div className='d-flex flex-column align-items-center mt-4'>
                    <span> Didn't receive the email? <span className='text-primary' role='button' onClick={handleResend}>Resend OTP</span></span>
                </div>
            </>
        </ForgetPasswordWrapper>

    )
}

export default VerifyOtp